import React from 'react';
import PropTypes from 'prop-types';
import ContentDivider from '../../../ContentDivider';
import SlickSlider from '../../../SlickSlider';
import settings from './constants';
import './EventViewSlider.scss';

const EventViewSlider = ({ children }) => (
  <div className="event-view-slider">
    <ContentDivider className="d-md-none" isHorizontal />
    <SlickSlider {...settings}>{children}</SlickSlider>
    <ContentDivider className="d-md-none" isHorizontal />
  </div>
);

EventViewSlider.propTypes = {
  children: PropTypes.node,
};

EventViewSlider.defaultProps = {
  children: null,
};

export default EventViewSlider;
