import React from 'react';
import { Redirect, withRouter, matchPath } from 'react-router-dom';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AppRoute from '../AppRoute';
import ModalRoute from '../ModalRoute';

const ProtectedRoute = (props, { previousLocation }) => {
  const { layout, layoutProps, logged, match, modalProps, location, modal, ...rest } = props;
  const isLocationMatched = !!matchPath(location.pathname, {
    path: rest.path,
  });

  const isPreviousLocationMatched = !!matchPath(previousLocation.pathname, {
    path: rest.path,
  });

  if (
    location.pathname === `/${match.params.lang}/sign-up` ||
    (!isLocationMatched && (!location.state || !location.state.modal || !isPreviousLocationMatched))
  ) {
    return null;
  }

  if (!logged) {
    const hasPreviousLocation = previousLocation && location.pathname !== previousLocation.pathname;
    const replacePrevLocation = get(location, 'state.prevLocation', { pathname: `/${match.params.lang}/` });

    return (
      <Redirect
        from={hasPreviousLocation ? previousLocation.pathname : '/'}
        to={{
          pathname: `/${match.params.lang}/sign-up`,
          state: { modal: hasPreviousLocation, replacePrevLocation, returnUrl: location.pathname },
        }}
      />
    );
  }

  const { component, ...routeProps } = rest;

  if (modal) {
    return <ModalRoute {...rest} modalProps={modalProps} />;
  }

  return <AppRoute layout={layout} layoutProps={layoutProps} component={component} {...routeProps} />;
};

ProtectedRoute.propTypes = {
  component: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  logged: PropTypes.bool.isRequired,
  match: PropTypes.shape({
    params: PropTypes.object.isRequired,
  }).isRequired,
  layout: PropTypes.func,
  layoutProps: PropTypes.object,
  modal: PropTypes.bool,
  modalProps: PropTypes.object,
};
ProtectedRoute.contextTypes = {
  previousLocation: PropTypes.object,
};
ProtectedRoute.defaultProps = {
  layoutProps: {},
  modal: false,
  modalProps: {},
  layout: null,
};

const mapStateToProps = ({ auth: { logged } }) => ({ logged });

export default withRouter(connect(mapStateToProps)(ProtectedRoute));
