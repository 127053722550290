import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withLocalization from '../../utils/withLocalization';
import Icon from '../Icon';
import './Status.scss';

const Status = ({ status, icon, label, className, t }) => (
  <div className={classNames('status', status, className)}>
    <Icon icon={icon} className="status__icon" />
    {t(label)}
  </div>
);

Status.propTypes = {
  status: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  t: PropTypes.func.isRequired,
};
Status.defaultProps = {
  className: null,
};

export default withLocalization(Status);
