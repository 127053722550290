const cms = {
  sendSignInEvent: (playerUUID) =>
    fetch(`/api/cms/auth/signin/event`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ playerUUID }),
    }),
};

export default cms;
