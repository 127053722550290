import gql from 'graphql-tag';

const createPayment = gql`
  mutation createPayment(
    $type: PaymentType!
    $playerUUID: String!
    $amount: Float!
    $currency: String!
    $paymentMethod: String!
    $device: FingerprintInput!
    $account: String
    $expDate: String
    $cardHolderName: String
    $cardHolderSurname: String
    $bankId: String
    $userAdditions: String
    $additionalFields: PaymentAdditionalFieldsScalar
    $tipAmount: Float
  ) {
    payment {
      create(
        type: $type
        playerUUID: $playerUUID
        amount: $amount
        currency: $currency
        paymentMethod: $paymentMethod
        device: $device
        account: $account
        expDate: $expDate
        cardHolderName: $cardHolderName
        cardHolderSurname: $cardHolderSurname
        bankId: $bankId
        userAdditions: $userAdditions
        additionalFields: $additionalFields
        tipAmount: $tipAmount
      ) {
        data {
          redirectUrl
          redirecting
          generationDate
          paymentId
        }
        error {
          error
          fields_errors
        }
      }
    }
  }
`;

export default createPayment;
