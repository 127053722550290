import React from 'react';
import { Waypoint } from 'react-waypoint';
import classNames from 'classnames';
import PropTypes from '../../constants/prop-types';
import GameItem from '../GameItem';
import ShortPreloader from '../ShortPreloader';
import GameGridLoadMore from './GameGridLoadMore';
import NothingFoundBlock from '../NothingFoundBlock';
import './GameGrid.scss';

const GameGrid = ({
  loadMoreGames,
  showMoreGamesButton,
  games,
  showWaypoint,
  logged,
  removeFromFavorites,
  addToFavorites,
  className,
  onGameButtonClick,
  inModal,
  locale,
}) => (
  <Choose>
    <When condition={!games.length}>
      <NothingFoundBlock label="t.games.not.found" />
    </When>
    <Otherwise>
      <div className={classNames('game-grid', className)}>
        {games.map((game) => (
          <GameItem
            key={game.internalGameId}
            logged={logged}
            addToFavorites={addToFavorites}
            removeFromFavorites={removeFromFavorites}
            onGameButtonClick={onGameButtonClick}
            locale={locale}
            {...game}
          />
        ))}
      </div>
      <If condition={showMoreGamesButton}>
        <GameGridLoadMore onClick={loadMoreGames} />
      </If>
      <If condition={showWaypoint}>
        <Waypoint scrollableAncestor={!inModal && window} onEnter={loadMoreGames}>
          <div className="game-grid__preloader">
            <ShortPreloader />
          </div>
        </Waypoint>
      </If>
    </Otherwise>
  </Choose>
);

GameGrid.propTypes = {
  removeFromFavorites: PropTypes.func,
  addToFavorites: PropTypes.func,
  loadMoreGames: PropTypes.func,
  games: PropTypes.arrayOf(PropTypes.game).isRequired,
  showWaypoint: PropTypes.bool,
  showMoreGamesButton: PropTypes.bool,
  logged: PropTypes.bool.isRequired,
  className: PropTypes.string,
  onGameButtonClick: PropTypes.func,
  inModal: PropTypes.bool,
  locale: PropTypes.string.isRequired,
};

GameGrid.defaultProps = {
  removeFromFavorites: null,
  addToFavorites: null,
  loadMoreGames: null,
  showMoreGamesButton: false,
  showWaypoint: false,
  className: null,
  onGameButtonClick: null,
  inModal: false,
};

export default GameGrid;
