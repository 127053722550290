import keyMirror from 'keymirror';

const currencyCodes = keyMirror({
  EUR: null,
  USD: null,
  RUB: null,
  PLN: null,
  ZAR: null,
  TRY: null,
  ARS: null,
  CLP: null,
  MXN: null,
  PEN: null,
  NOK: null,
  BRL: null,
  CAD: null,
  AUD: null,
  CHF: null,
  CZK: null,
  KZT: null,
});

const currencySettings = {
  [currencyCodes.EUR]: {
    symbol: '€',
    symbolOnLeft: true,
    order: 2,
    minPlayableBalance: 1,
  },
  [currencyCodes.USD]: {
    symbol: '$',
    symbolOnLeft: true,
    order: 1,
    minPlayableBalance: 1,
  },
  [currencyCodes.RUB]: {
    symbol: '₽',
    symbolOnLeft: false,
    order: 3,
    minPlayableBalance: 20,
  },
  [currencyCodes.PLN]: {
    symbol: 'zł',
    symbolOnLeft: false,
    order: 4,
    minPlayableBalance: 4,
  },
  [currencyCodes.ZAR]: {
    symbol: 'R',
    symbolOnLeft: true,
    order: 5,
    minPlayableBalance: 4,
  },
  [currencyCodes.TRY]: {
    symbol: '₺',
    symbolOnLeft: false,
    order: 1,
    minPlayableBalance: 4,
  },
  [currencyCodes.ARS]: {
    symbol: '$',
    symbolOnLeft: false,
    order: 6,
    minPlayableBalance: 10,
  },
  [currencyCodes.CLP]: {
    symbol: '$',
    symbolOnLeft: false,
    order: 10,
    minPlayableBalance: 100,
  },
  [currencyCodes.MXN]: {
    symbol: '$',
    symbolOnLeft: false,
    order: 8,
    minPlayableBalance: 10,
  },
  [currencyCodes.PEN]: {
    symbol: 'S/',
    symbolOnLeft: false,
    order: 9,
    minPlayableBalance: 1,
  },
  [currencyCodes.NOK]: {
    symbol: 'kr',
    symbolOnLeft: false,
    order: 11,
    minPlayableBalance: 8,
  },
  [currencyCodes.BRL]: {
    symbol: 'R$',
    symbolOnLeft: false,
    order: 12,
    minPlayableBalance: 4,
  },
  [currencyCodes.CAD]: {
    symbol: 'C$',
    order: 13,
    minPlayableBalance: 1,
  },
  [currencyCodes.AUD]: {
    symbol: 'A$',
    order: 14,
    minPlayableBalance: 1,
  },
  [currencyCodes.CHF]: {
    symbol: 'Fr.',
    order: 15,
    minPlayableBalance: 1,
  },
  [currencyCodes.CZK]: {
    symbol: 'Kč',
    order: 16,
    minPlayableBalance: 25,
  },
  [currencyCodes.KZT]: {
    symbol: '₸',
    order: 17,
    minPlayableBalance: 500,
  },
};

export { currencyCodes, currencySettings };
