const settings = {
  slidesToShow: 4,
  responsive: [
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
        centerMode: true,
        centerPadding: '75px',
      },
    },
  ],
};

export default settings;
