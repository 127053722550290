import React from 'react';
import PropTypes from 'prop-types';
import { NavbarToggler } from 'reactstrap';
import classNames from 'classnames';
import './Toggler.scss';

const Toggler = ({ clicked, onClick }) => (
  <NavbarToggler className={classNames('toggler', { activated: clicked })} onClick={onClick}>
    <span />
    <span />
    <span />
  </NavbarToggler>
);

Toggler.propTypes = {
  clicked: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default Toggler;
