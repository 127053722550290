import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './CustomRow.scss';

const CustomRow = ({ children, className }) => (
  <div className={classNames('row custom-row', className)}>{children}</div>
);

CustomRow.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
};
CustomRow.defaultProps = {
  className: null,
};

export default CustomRow;
