import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'react-apollo';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import I18n from 'redux-i18n';
import { availableLanguages, defaultLanguage } from '../constants/languages';
import buildQueryString from '../utils/buildQueryString';
import { Localization } from '../router';
import parseJson from '../utils/parseJson';
import { getDomain } from '../config';
import Maintenance from './Maintenance';
import Support from '../components/Support';
import { withGeoLocation } from '../utils';

const INTERVAL = 30000;

class MaintenanceProvider extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    lang: PropTypes.string.isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
    }).isRequired,
    geoLocation: PropTypes.shape({
      language: PropTypes.string.isRequired,
      currency: PropTypes.string.isRequired,
      country: PropTypes.string,
    }),
  };

  state = {
    maintenance: false,
    cmsDisabled: false,
  };

  async componentDidMount() {
    this.check();

    this.polling = setInterval(this.check, INTERVAL);
  }

  componentWillUnmount() {
    if (this.polling) {
      clearInterval(this.polling);
    }
  }

  get locale() {
    const {
      lang,
      geoLocation: { language: geoLocationLang },
    } = this.props;

    if (geoLocationLang && availableLanguages.includes(geoLocationLang)) {
      return geoLocationLang;
    }

    return lang || defaultLanguage;
  }

  polling = null;

  check = () => {
    fetch(`/api/cms/domain/${getDomain()}?${buildQueryString({ locale: this.locale })}`)
      .then((response) => response.text())
      .then((resp) => {
        const response = parseJson(resp);

        this.setState({
          maintenance: !Object.prototype.hasOwnProperty.call(response, 'maintenance') || response.maintenance,
          cmsDisabled: false,
        });
      })
      .catch(() => {
        this.setState({ maintenance: true, cmsDisabled: true });
      });
  };

  render() {
    const { children, lang } = this.props;
    const { maintenance, cmsDisabled } = this.state;

    return (
      <Fragment>
        <Choose>
          <When condition={maintenance && process.env.NODE_ENV !== 'development'}>
            <I18n translations={{}} useReducer>
              <Localization match={{ params: { lang: this.locale } }}>
                <Maintenance showBonusForm={!cmsDisabled} />
              </Localization>
            </I18n>
          </When>
          <Otherwise>{children}</Otherwise>
        </Choose>
        <Support lang={lang} />
      </Fragment>
    );
  }
}

export default compose(
  withRouter,
  connect(({ i18nState: { lang } }) => ({ lang })),
  withGeoLocation
)(MaintenanceProvider);
