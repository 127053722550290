import React from 'react';
import PropTypes from 'prop-types';
import withLocalization from '../../utils/withLocalization';
import InfoModal from '../InfoModal';
import SuccessBlock from '../SuccessBlock';

const SuccessModal = ({ title, content, handleCloseModal, isOpen, t }) => (
  <InfoModal title={t(title)} onClose={handleCloseModal} onButtonClick={handleCloseModal} isOpen={isOpen}>
    <SuccessBlock className="col-sm-8 col-md-6 mx-sm-auto">{t(content)}</SuccessBlock>
  </InfoModal>
);

SuccessModal.propTypes = {
  title: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  content: PropTypes.string.isRequired,
};

export default withLocalization(SuccessModal);
