import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import SuccessModal from '../../../components/SuccessModal';
import ErrorModal from '../../../components/ErrorModal';
import history from '../../../router/history';
import { ACTIONS } from '../../../constants/notifications';

const NotificationModals = ({ location: { query } }) => (
  <Fragment>
    <SuccessModal
      handleCloseModal={NotificationModals.handleCloseModal}
      title="t.deposit.success.title"
      content="t.deposit.success.content"
      isOpen={!!query && query.action === ACTIONS.DEPOSIT && query.success === 'true'}
    />
    <ErrorModal
      handleCloseModal={NotificationModals.handleCloseModal}
      title="t.payment.failed.title"
      content="t.payment.failed.desc"
      isOpen={!!query && query.action === ACTIONS.DEPOSIT && query.success === 'false'}
    />
  </Fragment>
);

NotificationModals.handleCloseModal = () => {
  history.replace(history.location.pathname);
};

NotificationModals.propTypes = {
  location: PropTypes.shape({
    query: PropTypes.shape({
      action: PropTypes.string,
      success: PropTypes.string,
    }),
  }).isRequired,
};

export default NotificationModals;
