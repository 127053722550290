const LIGHT = 'light';
const DARK = 'dark';

const themes = {
  LIGHT,
  DARK,
};

const themeLabels = {
  [LIGHT]: 't.theme.light',
  [DARK]: 't.theme.dark',
};

const availableThemes = [LIGHT, DARK];

export { themes, themeLabels, availableThemes };
