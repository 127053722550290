import { PureComponent } from 'react';
import PropTypes from '../../constants/prop-types';
import PostRegistrationModal from '../PostRegistrationModal';
import ErrorModal from '../ErrorModal';
import { withModals } from '../../utils';

class MainModals extends PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
    modals: PropTypes.shape({
      postRegistrationModal: PropTypes.modalType,
      errorModal: PropTypes.modalType,
    }).isRequired,
  };

  static childContextTypes = {
    mainModals: PropTypes.object,
  };

  getChildContext() {
    const { modals } = this.props;

    return {
      mainModals: modals,
    };
  }

  render() {
    const { children } = this.props;

    return children;
  }
}

export default withModals({
  postRegistrationModal: PostRegistrationModal,
  errorModal: ErrorModal,
})(MainModals);
