import createReducer from '../../utils/createReducer';

const KEY = 'promoParams';
const SET_PROMO_FIELDS = `${KEY}/set-promo-fields`;

function setPromoFields(fields) {
  return {
    type: SET_PROMO_FIELDS,
    payload: fields,
  };
}

const initialState = {};
const actionHandlers = {
  [SET_PROMO_FIELDS]: (state, { payload: fields }) => ({ ...state, ...fields }),
};

const actionTypes = { SET_PROMO_FIELDS };
const actionCreators = {
  setPromoFields,
};

export { initialState, actionTypes, actionCreators, actionHandlers };

export default createReducer(initialState, actionHandlers);
