import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon';

const SliderArrowPrev = ({ onClick, outline }) => (
  <Choose>
    <When condition={outline}>
      <Icon
        tag="button"
        type="button"
        onClick={onClick}
        icon="slider-arrow-left"
        className="slider-arrows-outline slider-arrows-outline--left"
      />
    </When>
    <Otherwise>
      <div className="slider-arrows slider-arrows--left">
        <Icon tag="button" type="button" icon="angle-left" onClick={onClick} className="slider-arrows__content" />
      </div>
    </Otherwise>
  </Choose>
);

SliderArrowPrev.propTypes = {
  onClick: PropTypes.func,
  outline: PropTypes.bool,
};
SliderArrowPrev.defaultProps = {
  onClick: null,
  outline: false,
};

export default SliderArrowPrev;
