import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { parse } from 'qs';
import get from 'lodash/get';
import trimStart from 'lodash/trimStart';

class AccountVerification extends Component {
  static propTypes = {
    verifyAccount: PropTypes.func.isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        lang: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  };

  state = {
    error: '',
    successActivation: '',
    redirect: false,
  };

  async componentWillMount() {
    const {
      location: { search },
      verifyAccount,
    } = this.props;
    const { token: verificationToken } = parse(trimStart(search, '?'));
    const historyLength = window.history.length;

    if (historyLength > 0) {
      window.history.go(-historyLength);
    }

    if (verificationToken) {
      const verifyResult = await verifyAccount({ variables: { verificationToken } });
      const error = get(verifyResult, 'data.player.verifyAccount.error.error');

      if (!error) {
        this.setState({
          redirect: true,
          successActivation: {
            title: 't.verify_account.success',
            desc: 't.verify_account.success.desc',
          },
        });
      } else {
        this.setState({
          redirect: true,
          error: {
            title: error,
            desc: 'error.validation.account-verification.failed.desc',
          },
        });
      }
    } else {
      this.setState({
        redirect: true,
        error: {
          title: 'error.validation.account-verification.failed',
          desc: 'error.validation.account-verification.failed.desc',
        },
      });
    }
  }

  render() {
    if (!this.state.redirect) {
      return null;
    }

    const { error, successActivation } = this.state;
    const {
      match: {
        params: { lang },
      },
    } = this.props;

    return <Redirect to={{ pathname: `/${lang}`, query: { error, successActivation } }} />;
  }
}

export default AccountVerification;
