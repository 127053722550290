import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { withApollo, compose } from 'react-apollo';

class Logout extends Component {
  static propTypes = {
    logout: PropTypes.func.isRequired,
    client: PropTypes.object.isRequired,
    lang: PropTypes.string.isRequired,
  };

  state = {
    logged: true,
  };

  async componentWillMount() {
    await this.props.logout();
    await this.props.client.resetStore();
    this.setState({ logged: false });
  }

  render() {
    if (this.state.logged) {
      return null;
    }

    return <Redirect to={`/${this.props.lang}`} />;
  }
}

export default compose(
  connect(({ i18nState: { lang } }) => ({ lang })),
  withApollo
)(Logout);
