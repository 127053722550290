import mapValues from 'lodash/mapValues';
import { SubmissionError } from 'redux-form';

export default (error) => {
  const fieldsErrors = error.fields_errors
    ? mapValues(error.fields_errors, 'error')
    : error.error === 'error.validation.phone.exist'
    ? { phone: error.error, phoneCode: error.error }
    : undefined;

  return new SubmissionError({ _error: !fieldsErrors ? error.error : '', ...fieldsErrors });
};
