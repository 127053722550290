import get from 'lodash/get';
import config from '../config';

const getPhoneCodeList = (profile) =>
  get(profile, 'options.signUp.post.phoneCode.list', [])
    .filter((phoneCode) => !config.restrictedPhoneCodes.includes(phoneCode))
    .map((code) => ({
      label: `+${code}`,
      value: code.replace('-', ''),
    }));

export default getPhoneCodeList;
