import React from 'react';
import PropTypes from 'prop-types';
import ShortPreloader from '../ShortPreloader';
import Link from '../Link';

const LoadingLink = ({ loading, children, ...props }) => (
  <Link {...props}>
    <If condition={loading}>
      <ShortPreloader type="link" />
    </If>
    {children}
  </Link>
);

LoadingLink.propTypes = {
  loading: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

export default LoadingLink;
