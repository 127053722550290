import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import FieldLabel from '../FieldLabel';
import FieldError from '../FieldError';
import './FormGroup.scss';

const FormGroup = (props) => {
  const {
    children,
    touched,
    error,
    label,
    labelClassName,
    customError,
    hideErrorMessage,
    required,
    className,
    horizontal,
    inputWrapperClassName,
    additionalBlock,
  } = props;

  const errorView = !hideErrorMessage && (customError || (touched && error));

  if (horizontal) {
    const labelClass = labelClassName || 'col-form-label';

    return (
      <div className={classNames('form-group form-row align-items-top', className)}>
        <FieldLabel label={label} className={classNames(labelClass, 'mb-md-0')} required={required} />
        <div className="form-row col-md">
          <div className={inputWrapperClassName}>{children}</div>
          <If condition={additionalBlock}>{additionalBlock}</If>
          <If condition={errorView}>
            <FieldError error={error} className="col-12" />
          </If>
        </div>
      </div>
    );
  }

  return (
    <div className={classNames('form-group', className)}>
      <FieldLabel label={label} className={labelClassName} required={required} />
      {children}
      <If condition={errorView}>
        <FieldError error={error} />
      </If>
    </div>
  );
};

FormGroup.propTypes = {
  children: PropTypes.node.isRequired,
  touched: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  labelClassName: PropTypes.string,
  customError: PropTypes.string,
  hideErrorMessage: PropTypes.bool,
  required: PropTypes.bool,
  className: PropTypes.string,
  horizontal: PropTypes.bool,
  inputWrapperClassName: PropTypes.string,
  additionalBlock: PropTypes.element,
};
FormGroup.defaultProps = {
  touched: false,
  error: null,
  label: null,
  labelClassName: null,
  customError: null,
  hideErrorMessage: false,
  required: false,
  className: null,
  horizontal: false,
  inputWrapperClassName: 'col-md',
  additionalBlock: null,
};

export default FormGroup;
