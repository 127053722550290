import gql from 'graphql-tag';

const updateLanguageCode = gql`
  mutation updateLanguageCode($playerUUID: String!, $languageCode: String) {
    player {
      update(playerUUID: $playerUUID, languageCode: $languageCode) {
        data {
          _id
          languageCode
        }
      }
    }
  }
`;

export default updateLanguageCode;
