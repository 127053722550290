import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './ResponsiveTableRow.scss';

const ResponsiveTableRow = ({ children, className }) => (
  <tr className={classNames('responsive-table-row', className)}>
    {React.Children.map(children, (child, key) =>
      React.cloneElement(child, {
        key,
        columnKey: key,
      })
    )}
  </tr>
);

ResponsiveTableRow.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

ResponsiveTableRow.defaultProps = {
  children: null,
  className: null,
};

export default ResponsiveTableRow;
