import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import MaintenanceForm from './MaintenanceForm';
import CustomPage from '../components/CustomPage';

const bodyNotFoundClass = 'not-found-view';

class Maintenance extends PureComponent {
  static propTypes = {
    showBonusForm: PropTypes.bool,
  };

  static defaultProps = {
    showBonusForm: false,
  };

  componentDidMount() {
    if (!document.body.classList.contains(bodyNotFoundClass)) {
      document.body.classList.add(bodyNotFoundClass);
    }
  }

  componentWillUnmount() {
    if (document.body.classList.contains(bodyNotFoundClass)) {
      document.body.classList.remove(bodyNotFoundClass);
    }
  }

  render() {
    const { showBonusForm } = this.props;

    return (
      <CustomPage title="t.maintenance.title" description="t.maintenance.description">
        <If condition={showBonusForm}>
          <div className="col-10 col-sm-6 order-sm-2 mb-4 mb-sm-0">
            <MaintenanceForm />
          </div>
        </If>
      </CustomPage>
    );
  }
}

export default Maintenance;
