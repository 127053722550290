import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './ShortPreloader.scss';

const ShortPreloader = ({ type, className }) => (
  <div className={classNames('short-preloader', type, className)}>
    <div className="short-preloader__wrapper">
      <span className="short-preloader__dot" />
      <span className="short-preloader__dot" />
      <span className="short-preloader__dot" />
      <span className="short-preloader__dot" />
      <span className="short-preloader__dot" />
    </div>
  </div>
);

ShortPreloader.propTypes = {
  type: PropTypes.oneOf(['custom', 'link', 'button']),
  className: PropTypes.string,
};

ShortPreloader.defaultProps = {
  type: null,
  className: null,
};

export default ShortPreloader;
