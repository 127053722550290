import React from 'react';
import PropTypes from 'prop-types';
import { Img } from 'react-image';
import Preloader from '../Preloader';
import './GameItemImage.scss';

const GameItemImageLoader = () => <Preloader className="game-item-image__loader" dotsHidden />;

const GameItemImage = ({ src, alt }) => (
  <Img
    className="game-item-image"
    src={src}
    alt={alt}
    loader={<GameItemImageLoader />}
    unloader={<GameItemImageLoader />}
  />
);

GameItemImage.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
};

GameItemImage.defaultProps = {
  src: null,
  alt: '',
};

export default GameItemImage;
