import React from 'react';
import PropTypes from 'prop-types';
import ShortPreloader from '../ShortPreloader';

const LoadingButton = ({ loading, children, ...props }) => (
  <button {...props} disabled={loading}>
    <If condition={loading}>
      <ShortPreloader type="button" />
    </If>
    {children}
  </button>
);

LoadingButton.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.node.isRequired,
};
LoadingButton.defaultProps = {
  loading: false,
};

export default LoadingButton;
