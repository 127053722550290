import gql from 'graphql-tag';

const suspendMutation = gql`
  mutation suspend(
    $playerUUID: String!
    $reason: String!
    $duration: SuspendDuration
    $permanent: Boolean
    $comment: String
  ) {
    player {
      suspend(playerUUID: $playerUUID, reason: $reason, duration: $duration, permanent: $permanent, comment: $comment) {
        data {
          suspendEndDate
        }
        error {
          error
        }
      }
    }
  }
`;

export { suspendMutation };
