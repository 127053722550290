import React from 'react';
import { UncontrolledTooltip, Progress } from 'reactstrap';
import classNames from 'classnames';
import PropTypes from '../../constants/prop-types';
import { FULFILLMENTS } from '../../constants/bonus';
import { withLocalization, getCurrencySymbol } from '../../utils';
import './ActiveBonusProgressBar.scss';

const ActiveBonusProgressBar = (props) => {
  const {
    id,
    bonus: { progress, amountToWage, bonusType, leftToWage },
    className,
    t,
  } = props;

  const leftToWageValue = `${leftToWage.amount} ${getCurrencySymbol(leftToWage.currency)}`;
  const amountToWageValue = `${amountToWage.amount} ${getCurrencySymbol(amountToWage.currency)}`;

  return (
    <div className={classNames('active-bonus-progress-bar', className)}>
      <div className="active-bonus-progress-bar__status">
        {t('t.activate.bonus', { progress: Math.round(progress * 100) / 100 })}
      </div>
      <Progress
        id={id}
        className="active-bonus-progress-bar__progress"
        barClassName="active-bonus-progress-bar__progress-bar"
        value={progress}
      />
      <UncontrolledTooltip
        className="active-bonus-progress-bar__tooltip"
        innerClassName="active-bonus-progress-bar__tooltip-content"
        placement="bottom"
        target={id}
        autohide={false}
      >
        <Choose>
          <When condition={bonusType === FULFILLMENTS.DEPOSIT}>
            {t('t.activate.bonus.amount.to.deposit', {
              leftToWage: leftToWageValue,
              amountToWage: amountToWageValue,
            })}
          </When>
          <Otherwise>
            {t('t.activate.bonus.amount.to.wage', {
              leftToWage: leftToWageValue,
              amountToWage: amountToWageValue,
            })}
          </Otherwise>
        </Choose>
      </UncontrolledTooltip>
    </div>
  );
};

ActiveBonusProgressBar.propTypes = {
  t: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  bonus: PropTypes.shape({
    bonusType: PropTypes.string,
    progress: PropTypes.number,
    amountToWage: PropTypes.money,
    leftToWage: PropTypes.money,
  }).isRequired,
  className: PropTypes.string,
};
ActiveBonusProgressBar.defaultProps = {
  className: null,
};

export default withLocalization(ActiveBonusProgressBar);
