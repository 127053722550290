import { graphql, compose } from 'react-apollo';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import LanguageMenu from './LanguageMenu';
import { withLocalization, withAvailableLanguages } from '../../utils';
import { updateLanguageCode } from '../../apollo/mutations';

export default compose(
  withRouter,
  connect(({ auth: { logged, uuid } }) => ({ logged, uuid })),
  graphql(updateLanguageCode, {
    name: 'updateLanguage',
    options: ({ uuid }) => ({
      variables: {
        playerUUID: uuid,
      },
    }),
  }),
  withLocalization,
  withAvailableLanguages
)(LanguageMenu);
