import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withLocalization from '../../../utils/withLocalization';
import './FieldError.scss';

const FieldError = ({ error, t, className }) => <div className={classNames('field-error', className)}>{t(error)}</div>;

FieldError.propTypes = {
  error: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  className: PropTypes.string,
};
FieldError.defaultProps = {
  className: null,
};

export default withLocalization(FieldError);
