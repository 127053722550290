import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import IgromatGame from '../components/IgromatGame';
import withLocalization from '../../../utils/withLocalization';

export default compose(
  withLocalization,
  withRouter,
  connect(({ auth: { uuid, token }, i18nState: { lang } }) => ({ uuid, token, lang }))
)(IgromatGame);
