import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withLocalization } from '../../utils';
import './NothingFoundBlock.scss';

const NothingFoundBlock = ({ t, label, className }) => (
  <div className={classNames('nothing-found-block', className)}>{t(label)}</div>
);

NothingFoundBlock.propTypes = {
  t: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
};

NothingFoundBlock.defaultProps = {
  className: null,
};

export default withLocalization(NothingFoundBlock);
