import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import NavigationLink from '../NavigationLink';
import './TabLink.scss';

const TabLink = ({ className, ...rest }) => <NavigationLink {...rest} className={classNames('tab-link', className)} />;

TabLink.propTypes = {
  className: PropTypes.string,
};

TabLink.defaultProps = {
  className: null,
};

export default memo(TabLink);
