import React, { Component } from 'react';
import get from 'lodash/get';
import PropTypes from '../../../constants/prop-types';
import ShortPreloader from '../../../components/ShortPreloader';
import history from '../../../router/history';
import './VeliPlayeGameView.scss';

class VeliPlayGameView extends Component {
  static propTypes = {
    lang: PropTypes.string.isRequired,
    startGame: PropTypes.func.isRequired,
    stopGame: PropTypes.func.isRequired,
  };

  static contextTypes = {
    mainModals: PropTypes.shape({
      errorModal: PropTypes.modalType,
    }),
  };

  static defaultProps = {};

  state = {
    data: null,
    error: null,
    loading: true,
  };

  handlePostMessage = (event) => {
    if (this.state.data && this.state.data.gameSessionURL && this.state.data.gameSessionURL.startsWith(event.origin)) {
      if (typeof event.data === 'object' && event.data !== null) {
        if (event.data.name === 'refreshSession') {
          window.location.reload();
        } else if (event.data.name === 'exitToLobby') {
          const { lang } = this.props;

          history.push({ pathname: `/${lang}/games` });
        }
      }
    }
  };

  async componentDidMount() {
    this.mounted = true;

    await this.startGame();

    window.addEventListener('message', this.handlePostMessage, false);
  }

  componentDidUpdate({ lang: prevLang, profile: prevProfile }) {
    const { lang } = this.props;

    if (lang !== prevLang) {
      this.startGame();
    }
  }

  async componentWillUnmount() {
    this.mounted = false;

    window.removeEventListener('message', this.handlePostMessage);

    await this.stopGame();
  }

  mounted = false;

  startGame = () => {
    const { startGame } = this.props;

    return startGame().then(async (response) => {
      const { data, error } = get(response, 'data.game.start', this.state);
      const nextState = { loading: false, data, error };

      if (this.mounted) {
        this.setState(nextState);
      }
    });
  };

  stopGame = async () => {
    const { stopGame } = this.props;
    const { data } = this.state;

    if (data && data.gameSessionId) {
      await stopGame({ variables: { sessionId: data.gameSessionId } });
    }
  };

  render() {
    const { loading, error, data } = this.state;

    if (loading) {
      return <ShortPreloader />;
    }

    if (error) {
      return <div>{error}</div>;
    }

    return (
      <div className="veliplay-game-view">
        <iframe width="100%" height="100%" allowFullScreen src={data.gameSessionURL} title="VeliPlay Game" />
      </div>
    );
  }
}

export default VeliPlayGameView;
