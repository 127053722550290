import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from '../../../Icon';

const ModalHeader = ({ toggle, title, className, closeActionClassName }) => (
  <div className={classNames('modal-header', className)}>
    {title && <h4 className="modal-title">{title}</h4>}
    <Icon
      tag="button"
      type="button"
      icon="close"
      className={classNames('modal-close', closeActionClassName)}
      data-dismiss="modal"
      aria-label="Close" // eslint-disable-line react/prop-types
      onClick={toggle}
    />
  </div>
);

ModalHeader.propTypes = {
  toggle: PropTypes.func,
  title: PropTypes.string,
  className: PropTypes.string,
  closeActionClassName: PropTypes.string,
};

ModalHeader.defaultProps = {
  toggle: null,
  title: null,
  className: null,
  closeActionClassName: null,
};

export default ModalHeader;
