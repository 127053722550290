import React from 'react';
import PropTypes from 'prop-types';
import SimpleBar from 'simplebar-react';
import './CustomScroll.scss';

const CustomScroll = ({ children, className, maxHeight, autoHide }) => (
  <SimpleBar className={className} style={{ maxHeight }} autoHide={autoHide}>
    {children}
  </SimpleBar>
);

CustomScroll.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  maxHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  autoHide: PropTypes.bool,
};

CustomScroll.defaultProps = {
  className: null,
  maxHeight: '100%',
  autoHide: true,
};

export default CustomScroll;
