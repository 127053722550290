import get from 'lodash/get';
import config, { getBrandId } from '../../config';

const { license, location } = get(config, 'liveChat', {});

const LIVE_CHAT_ID_ELEMENT = 'livechat-initial-script';

export const initLiveChat = (lang) => {
  window.__lc = window.__lc || {};
  window.__lc.license = license;
  window.__lc.group = location[lang];
  window.__lc.chat_between_groups = false;
  window.__lc.params = [{ name: 'brandId', value: getBrandId() }];
  window.__lc.visitor = {};

  return new Promise((resolve) => {
    (function (windowArgs, documentArgs, c) {
      function i(n) {
        // eslint-disable-next-line no-underscore-dangle
        return e._h ? e._h.apply(null, n) : e._q.push(n);
      }
      // eslint-disable-next-line
      var e = {
        _q: [],
        _h: null,
        _v: '2.0',
        on() {
          // eslint-disable-next-line prefer-rest-params
          i(['on', c.call(arguments)]);
        },
        once() {
          // eslint-disable-next-line prefer-rest-params
          i(['once', c.call(arguments)]);
        },
        off() {
          // eslint-disable-next-line prefer-rest-params
          i(['off', c.call(arguments)]);
        },
        get() {
          if (!e._h) throw new Error("[LiveChatWidget] You can't use getters before load."); // eslint-disable-line no-underscore-dangle
          // eslint-disable-next-line prefer-rest-params
          return i(['get', c.call(arguments)]);
        },
        call() {
          // eslint-disable-next-line prefer-rest-params
          i(['call', c.call(arguments)]);
        },
        init() {
          // eslint-disable-next-line
          var script = documentArgs.createElement('script');
          // eslint-disable-next-line no-unused-expressions
          (script.async = !0), // eslint-disable-line no-sequences
            (script.type = 'text/javascript'),
            (script.id = LIVE_CHAT_ID_ELEMENT),
            (script.src = 'https://cdn.livechatinc.com/tracking.js'),
            (script.onload = resolve),
            documentArgs.head.appendChild(script);
        },
      };
      // eslint-disable-next-line no-unused-expressions
      !windowArgs.__lc.asyncInit && e.init(); // eslint-disable-line no-underscore-dangle
      windowArgs.LiveChatWidget = windowArgs.LiveChatWidget || e;
    })(window, document, [].slice); //eslint-disable-line
  });
};

export const destroyLiveChat = () =>
  new Promise((resolve) => {
    const { LiveChatWidget } = window;

    if (LiveChatWidget) {
      LiveChatWidget.on('ready', () => {
        try {
          if (LiveChatWidget.get('customer_data').status !== 'chatting') {
            LiveChatWidget.call('destroy');

            const element = document.getElementById(LIVE_CHAT_ID_ELEMENT);

            if (element) {
              element.remove();
            }
          }
        } catch (e) {}

        resolve();
      });
    } else {
      resolve();
    }
  });
