import gql from 'graphql-tag';

const gameCategoriesQuery = gql`
  query gameNgCategories {
    gameNgCategories {
      key
      value
    }
  }
`;

export default gameCategoriesQuery;
