import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './FieldLabel.scss';

const FieldLabel = ({ label, className, required }) => (
  <If condition={label}>
    <label className={classNames('field-label', className)}>
      {label}
      <If condition={required}>
        <span className="field-label__icon">*</span>
      </If>
    </label>
  </If>
);

FieldLabel.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  className: PropTypes.string,
  required: PropTypes.bool,
};
FieldLabel.defaultProps = {
  label: null,
  className: null,
  required: false,
};

export default FieldLabel;
