import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import 'lazysizes';
import 'lazysizes/plugins/attrchange/ls.attrchange';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';

const LazyLoadImage = ({ src, alt, className }) => (
  <img className={classNames('lazyload', className)} data-src={src} alt={alt} />
);

LazyLoadImage.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  className: PropTypes.string,
};

LazyLoadImage.defaultProps = {
  src: '',
  alt: '',
  className: null,
};

export default LazyLoadImage;
