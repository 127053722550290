import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './Pin.scss';

const Pin = ({ children, className, size }) => <span className={classNames('pin', size, className)}>{children}</span>;

Pin.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'md']),
};

Pin.defaultProps = {
  children: null,
  className: null,
  size: 'sm',
};

export default memo(Pin);
