import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import AccountVerification from '../components/AccountVerification';

const verifyAccount = gql`
  mutation verifyAccount($verificationToken: String!) {
    player {
      verifyAccount(verificationToken: $verificationToken) {
        error {
          error
        }
        data {
          playerUUID
        }
      }
    }
  }
`;

export default graphql(verifyAccount, {
  name: 'verifyAccount',
})(AccountVerification);
