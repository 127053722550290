import gql from 'graphql-tag';

const startGame = gql`
  mutation startGame(
    $id: String!
    $gameType: GameType!
    $mode: GameMode!
    $brandId: String!
    $playerUUID: String
    $lang: String
    $country: String
    $exitUrl: String
  ) {
    game {
      start(
        id: $id
        type: $gameType
        mode: $mode
        brandId: $brandId
        playerUUID: $playerUUID
        lang: $lang
        country: $country
        exitUrl: $exitUrl
      ) {
        data {
          gameSessionId
          gameSessionURL
        }
        error {
          error
        }
      }
    }
  }
`;
const stopGame = gql`
  mutation stopGame($id: String!, $gameType: GameType!, $sessionId: String!, $playerUUID: String!, $country: String) {
    game {
      stop(id: $id, type: $gameType, sessionId: $sessionId, playerUUID: $playerUUID, country: $country) {
        data {
          sessionId
        }
        error {
          error
        }
      }
    }
  }
`;

export { startGame, stopGame };
