import orderBy from 'lodash/orderBy';
import get from 'lodash/get';
import config, { getCountryName } from '../config';

const getCountryList = (profile, t) =>
  orderBy(
    get(profile, 'options.signUp.post.country.list', [])
      .filter(({ countryCode }) => !config.restrictedCountries.includes(countryCode))
      .map(({ countryCode, order }) => ({
        label: t(getCountryName(countryCode) || countryCode),
        value: countryCode,
        order,
      })),
    ['order', 'label'],
    ['desc', 'asc']
  );

export default getCountryList;
