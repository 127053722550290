import { connect } from 'react-redux';
import { graphql, compose } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import gql from 'graphql-tag';
import ESportsBookView from '../components/ESportsBookView';
import { getBrandId } from '../../../config';
import { startGame, stopGame } from '../../../apollo/mutations/game';
import { getDeviceType, withGeoLocation } from '../../../utils';
import { modes } from '../../../constants/games';

const internalGameId = 'esport:DESKTOP_AND_MOBILE:digitain:digitain';

const profileBalance = gql`
  query getProfile($playerUUID: String!) {
    player(playerUUID: $playerUUID) {
      error {
        error
      }
      data {
        _id
        bonusBalance {
          amount
          currency
        }
      }
    }
  }
`;

const profileInfo = gql`
  query getProfile($playerUUID: String!) {
    player(playerUUID: $playerUUID) {
      data {
        _id
        completed
      }
    }
  }
`;

export default compose(
  withRouter,
  withGeoLocation,
  connect(({ auth: { uuid: playerUUID, token, logged }, i18nState: { lang } }) => ({
    playerUUID,
    lang,
    token,
    logged,
  })),
  graphql(profileBalance, {
    name: 'profile',
    skip: ({ playerUUID }) => !playerUUID,
    options: ({ playerUUID }) => ({ fetchPolicy: 'network-only', variables: { playerUUID } }),
  }),
  graphql(profileInfo, {
    name: 'profileInfo',
    skip: ({ playerUUID }) => !playerUUID,
    options: ({ playerUUID }) => ({ variables: { playerUUID } }),
  }),
  graphql(startGame, {
    name: 'startGame',
    options: ({ lang, playerUUID, geoLocation: { country } }) => ({
      variables: {
        id: internalGameId,
        gameType: getDeviceType(),
        mode: playerUUID ? modes.REAL : modes.DEMO,
        exitUrl: `${window.location.origin}/${lang}`,
        brandId: getBrandId(),
        playerUUID,
        lang,
        country,
      },
    }),
  }),
  graphql(stopGame, {
    name: 'stopGame',
    options: ({ playerUUID, geoLocation: { country } }) => ({
      variables: {
        id: internalGameId,
        gameType: getDeviceType(),
        playerUUID,
        country,
      },
    }),
  })
)(ESportsBookView);
