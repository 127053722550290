import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import get from 'lodash/get';
import { matchPath } from 'react-router-dom';
import Metrics from './Metrics';
import { availableLanguages } from '../../constants/languages';
import history from '../../router/history';
import { getDomain } from '../../config';

const domainDataQuery = gql`
  query domainData($domain: String!, $locale: String!) {
    domainData(domain: $domain, locale: $locale) {
      data {
        title
        footerDescription
        metaDescription
        yaMetrics
        gaWebmaster
        gaAnalytics
        yaWebmaster
      }
    }
  }
`;

export default graphql(domainDataQuery, {
  options() {
    const locale = get(
      matchPath(history.location.pathname, { path: `/:lang(${availableLanguages.join('|')})` }),
      'params.lang',
      availableLanguages[0]
    );

    return {
      variables: {
        domain: getDomain(),
        locale,
      },
    };
  },
  name: 'domainData',
})(Metrics);
