import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'reactstrap';

class CustomTooltip extends PureComponent {
  static propTypes = {
    target: PropTypes.string.isRequired,
    isOpen: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isOpen: false,
  };

  static getDerivedStateFromProps({ isOpen }, { isOpen: prevIsOpen }) {
    return prevIsOpen !== isOpen ? { isOpen } : null;
  }

  state = {
    isOpen: this.props.isOpen,
  };

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  }

  get target() {
    return document.getElementById(this.props.target);
  }

  handleClickOutside = (event) => {
    const { onClose } = this.props;
    const { target } = this;

    if (this.state.isOpen && (!target || !target.contains(event.target))) {
      event.stopPropagation();

      this.setState({ isOpen: false });

      if (typeof onClose === 'function') {
        onClose();
      }
    }
  };

  render() {
    const { onClose, ...props } = this.props;
    const { isOpen } = this.state;

    return <Tooltip {...props} isOpen={isOpen} />;
  }
}

export default CustomTooltip;
