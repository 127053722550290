const STATES = {
  IN_PROGRESS: 'IN_PROGRESS',
};

const BONUS_STATUSES = {
  INACTIVE: 'INACTIVE',
  IN_PROGRESS: 'IN_PROGRESS',
  ACTIVE: 'ACTIVE',
};

const FULFILLMENTS = {
  DEPOSIT: 'DEPOSIT',
  WITHOUT_FULFILMENT: 'WITHOUT_FULFILMENT',
};

const PLAYER_STATES = {
  OPT_IN: 'OPT_IN',
  VIEW: 'VIEW',
};

const BONUS_TYPES = {
  CAMPAIGN: 'CAMPAIGN',
  BONUS: 'BONUS',
  FREE_SPIN: 'FREE_SPIN',
};

export { BONUS_TYPES, STATES, FULFILLMENTS, BONUS_STATUSES, PLAYER_STATES };
