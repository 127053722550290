import React from 'react';
import { navigation } from '../../constants/menu';
import NavigationLink from '../NavigationLink';
import './Navigation.scss';

const Navigation = () => (
  <nav className="navigation">
    {navigation.map((item) => (
      <NavigationLink key={item.key} {...item} className="navigation__link" />
    ))}
  </nav>
);

export default Navigation;
