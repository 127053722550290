import get from 'lodash/get';

export default (content) => {
  const paramRegularExpressionString = '_sp.push\\((\\[([^\\]]+)\\])\\)';
  const matches = content.match(new RegExp(paramRegularExpressionString, 'g'));

  if (matches) {
    return matches.reduce((res, param) => {
      const match = param.match(new RegExp(paramRegularExpressionString));
      const matchedString = get(match, '[2]');

      if (matchedString) {
        const [, key, , value] = matchedString.split("'");

        return { ...res, [key]: value };
      }

      return res;
    }, {});
  }

  return null;
};
