import React from 'react';
import PropTypes from 'prop-types';
import { compose, graphql } from 'react-apollo';
import get from 'lodash/get';
import { connect } from 'react-redux';
import gql from 'graphql-tag';
import { availableLanguages } from '../constants/languages';

const profileQuery = gql`
  query ProfileLanguageCodeQuery($playerUUID: String!) {
    player(playerUUID: $playerUUID) {
      data {
        _id
        languageCode
      }
    }
  }
`;

const withAvailableLanguages = (WrappedComponent) => {
  const AvailableLanguagesComponent = ({ profile, uuid, ...rest }) => {
    const languages = [...availableLanguages];
    const playerLanguageLoading = get(profile, 'loading', false);

    if (uuid) {
      const playerLanguage = get(profile, 'player.data.languageCode');

      if (playerLanguage && !languages.includes(playerLanguage)) {
        languages.push(playerLanguage);
      }
    }

    return <WrappedComponent availableLanguages={languages} playerLanguageLoading={playerLanguageLoading} {...rest} />;
  };

  AvailableLanguagesComponent.propTypes = {
    profile: PropTypes.shape({
      player: PropTypes.shape({
        data: PropTypes.shape({
          languageCode: PropTypes.string,
        }),
      }),
    }),
    uuid: PropTypes.string,
  };

  AvailableLanguagesComponent.defaultProps = {
    profile: null,
    uuid: null,
  };

  return compose(
    connect(({ auth: { uuid, logged } }) => ({ uuid, logged })),
    graphql(profileQuery, {
      name: 'profile',
      options: ({ uuid }) => ({
        variables: {
          playerUUID: uuid,
        },
        fetchPolicy: 'cache-and-network',
      }),
      skip: ({ logged }) => !logged,
    })
  )(AvailableLanguagesComponent);
};

export default withAvailableLanguages;
