import React from 'react';
import PropTypes from 'prop-types';
import Link from '../Link';
import withAppTheme from '../../utils/withAppTheme';
import { getBrandId } from '../../config';

const Logo = ({ className, to, onClick, theme, logoClassName }) => {
  const image = <img className={logoClassName} src={`/img/themes/${theme}/logo.svg`} alt={`${getBrandId()} logo`} />;

  return (
    <Choose>
      <When condition={to}>
        <Link className={className} onClick={onClick} to={to}>
          {image}
        </Link>
      </When>
      <When condition={onClick}>
        <span className={className} onClick={onClick} role="presentation">
          {image}
        </span>
      </When>
      <Otherwise>{image}</Otherwise>
    </Choose>
  );
};

Logo.propTypes = {
  className: PropTypes.string,
  to: PropTypes.string,
  onClick: PropTypes.func,
  theme: PropTypes.string.isRequired,
  logoClassName: PropTypes.string,
};

Logo.defaultProps = {
  className: null,
  to: null,
  onClick: null,
  logoClassName: null,
};

export default withAppTheme(Logo);
