import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from '../Icon';
import './SupportButton.scss';

class SupportButton extends PureComponent {
  static propTypes = {
    label: PropTypes.string.isRequired,
    className: PropTypes.string,
    hideIcon: PropTypes.bool,
  };

  static defaultProps = {
    className: null,
    hideIcon: false,
  };

  static contextTypes = {
    auth: PropTypes.shape({
      logged: PropTypes.bool.isRequired,
    }),
  };

  static handleClick() {
    const { LiveChatWidget } = window;

    if (LiveChatWidget) {
      LiveChatWidget.call('maximize');
    }
  }

  render() {
    const { label, className, hideIcon } = this.props;

    return (
      <div className={classNames('support-button', className)}>
        <If condition={!hideIcon}>
          <Icon icon="question" className="support-button__icon" />
        </If>
        <button type="button" className="support-button__label" onClick={SupportButton.handleClick}>
          {label}
        </button>
      </div>
    );
  }
}

export default SupportButton;
