import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import Amount from '../Amount';
import AmountTemplate from '../AmountTemplate';
import Banner from '../Banner';
import Status from '../Status';
import PropTypes from '../../constants/prop-types';
import './EventView.scss';

const EventView = ({
  bannerDelayTime,
  bannerImage,
  bannerLabel,
  children,
  status,
  name,
  metaDescription,
  metaTitle,
  prizePool,
  prizeLabel,
}) => (
  <Fragment>
    <If condition={metaTitle || metaDescription}>
      <Helmet>
        <If condition={metaTitle}>
          <title>{metaTitle}</title>
        </If>
        <If condition={metaDescription}>
          <meta name="description" content={metaDescription} />
        </If>
      </Helmet>
    </If>
    <Banner image={bannerImage} timer delay={bannerDelayTime} label={bannerLabel} />
    <div className="container my-2 my-md-4">
      <div className="row">
        <div className="col-lg-10 col-xl-8 mx-lg-auto event-view">
          <Status status={status.alias} icon={status.icon} label={status.label} className="event-view__status" />
          <h1 className="event-view__name">
            <AmountTemplate value={name} />
          </h1>
          <div className="event-view__prize-label">{prizeLabel}</div>
          <Amount
            integer
            tag="div"
            className="event-view__prize-value"
            amount={prizePool.amount}
            currency={prizePool.currency}
          />
          {children}
        </div>
      </div>
    </div>
  </Fragment>
);

EventView.propTypes = {
  status: PropTypes.shape({
    alias: PropTypes.string,
    icon: PropTypes.string,
    label: PropTypes.string,
  }).isRequired,
  name: PropTypes.string,
  prizeLabel: PropTypes.string.isRequired,
  prizePool: PropTypes.money.isRequired,
  children: PropTypes.node,
  bannerDelayTime: PropTypes.number,
  bannerImage: PropTypes.string,
  bannerLabel: PropTypes.string,
  metaDescription: PropTypes.string,
  metaTitle: PropTypes.string,
};

EventView.defaultProps = {
  name: '',
  bannerDelayTime: null,
  bannerImage: '',
  bannerLabel: '',
  metaDescription: '',
  metaTitle: '',
};

export default EventView;
