import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import CustomTooltip from '../CustomTooltip';

const ElementWithControlledTooltip = (props) => {
  const { id, containerId, children, tooltipContent, ...rest } = props;

  return (
    <Fragment>
      {React.cloneElement(children, { id })}
      <CustomTooltip {...rest} target={id} container={containerId}>
        {tooltipContent}
      </CustomTooltip>
    </Fragment>
  );
};

ElementWithControlledTooltip.propTypes = {
  id: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  containerId: PropTypes.string,
  children: PropTypes.node.isRequired,
  tooltipContent: PropTypes.node.isRequired,
};

ElementWithControlledTooltip.defaultProps = {
  containerId: null,
};

export default ElementWithControlledTooltip;
