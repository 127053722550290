import React from 'react';
import PropTypes from 'prop-types';
import Logo from '../Logo';
import SupportButton from '../SupportButton';
import { withLocalization } from '../../utils';
import './CustomPage.scss';

const CustomPage = ({ t, title, description, children }) => (
  <main className="custom-page">
    <Logo className="custom-page__logo" />
    <div className="custom-page__title">{t(title)}</div>
    <div className="custom-page__description">{t(description)}</div>
    <div className="row no-gutters custom-page__content">
      <If condition={children}>{children}</If>
      <div className="col-10 col-sm-6 order-sm-1">
        <img className="img-fluid" src="/img/repair.png" alt="repair" />
      </div>
    </div>
    <SupportButton className="not-found__support" label={t('t.contact.support')} />
  </main>
);

CustomPage.propTypes = {
  t: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  children: PropTypes.node,
};

CustomPage.defaultProps = {
  children: null,
};

export default withLocalization(CustomPage);
