import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody } from '../Modal';
import { withLocalization } from '../../utils';
import Button from '../Button';
import './InfoModal.scss';

const InfoModal = ({
  title,
  buttonLabel,
  children,
  onClose,
  t,
  onButtonClick,
  subButtonLabel,
  onSubButtonClick,
  isOpen,
}) => (
  <Modal className="info-modal" isOpen={isOpen} backdrop="static" toggle={onClose}>
    <ModalHeader title={title} toggle={onClose} />
    <ModalBody>
      <div className="container-fluid">
        <div className="row">{children}</div>
        <div className="row">
          <div className="col-10 col-sm-7 col-md-5 mx-auto info-modal__actions">
            <Button type="button" className="info-modal__button" onClick={onButtonClick}>
              {buttonLabel || t('t.done')}
            </Button>
            <If condition={subButtonLabel && onSubButtonClick}>
              <button type="button" className="info-modal__sub-button" onClick={onSubButtonClick}>
                {subButtonLabel}
              </button>
            </If>
          </div>
        </div>
      </div>
    </ModalBody>
  </Modal>
);

InfoModal.propTypes = {
  title: PropTypes.PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
  onClose: PropTypes.func,
  t: PropTypes.func.isRequired,
  buttonLabel: PropTypes.string,
  onButtonClick: PropTypes.func.isRequired,
  subButtonLabel: PropTypes.string,
  onSubButtonClick: PropTypes.func,
  isOpen: PropTypes.bool,
};

InfoModal.defaultProps = {
  buttonLabel: '',
  subButtonLabel: '',
  onSubButtonClick: null,
  onClose: null,
  isOpen: true,
};

export default withLocalization(InfoModal);
