import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './FieldAction.scss';

const FieldAction = ({ onClick, label, small, disabled, className }) => (
  <button
    type="button"
    onClick={onClick}
    className={classNames('field-action', { small }, className)}
    disabled={disabled}
  >
    {label}
  </button>
);

FieldAction.propTypes = {
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  small: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

FieldAction.defaultProps = {
  small: false,
  disabled: false,
  className: null,
};

export default memo(FieldAction);
