import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './Sprite.scss';

const Sprite = ({ item, className }) => <span className={classNames(`sprite sprite-${item}`, className)} />;

Sprite.propTypes = {
  item: PropTypes.string.isRequired,
  className: PropTypes.string,
};
Sprite.defaultProps = {
  className: null,
};

export default Sprite;
