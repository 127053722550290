import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withLocalization from '../../utils/withLocalization';
import withAppTheme from '../../utils/withAppTheme';
import './ThemeSwitcher.scss';

const ThemeSwitcher = ({ theme, t, className, toggleTheme }) => (
  <button type="button" className={classNames('theme-switcher', theme, className)} onClick={toggleTheme}>
    {t(`t.header.theme.${theme}`)}
  </button>
);

ThemeSwitcher.propTypes = {
  theme: PropTypes.string.isRequired,
  toggleTheme: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  className: PropTypes.string,
};

ThemeSwitcher.defaultProps = {
  className: null,
};

export default compose(withAppTheme, withLocalization)(ThemeSwitcher);
