import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import Currency from './Currency';
import { currencySettings } from '../../constants/currency';

const formatMoney = (amount, integer) => {
  const n = amount.toString();
  const p = n.indexOf('.');
  const formattedMoney = n.replace(/\d(?=(?:\d{3})+(?:\.|$))/g, ($0, i) => (p < 0 || i < p ? `${$0} ` : $0));

  return integer ? formattedMoney.replace(/.(.\d)$/, '') : formattedMoney;
};

const Amount = ({ tag, className, amount, currency, amountClassName, currencyClassName, integer }) => {
  const parsedAmount = parseFloat(amount).toFixed(2);
  if (isNaN(parsedAmount)) {
    return null;
  }

  const symbolOnLeft = get(currencySettings, [currency, 'symbolOnLeft'], false);

  const formatteAmount = formatMoney(parsedAmount, integer);

  const chunks = [
    <Currency key="currency" code={currency} className={currencyClassName} />,
    <span key="amount" className={amountClassName}>
      {formatteAmount}
    </span>,
  ];

  if (!symbolOnLeft) {
    chunks.reverse();
  }

  return React.createElement(tag, { className }, chunks);
};

Amount.propTypes = {
  tag: PropTypes.string,
  className: PropTypes.string,
  integer: PropTypes.bool,
  amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  amountClassName: PropTypes.string,
  currency: PropTypes.string.isRequired,
  currencyClassName: PropTypes.string,
};

Amount.defaultProps = {
  tag: 'span',
  className: null,
  amountClassName: null,
  integer: false,
  currencyClassName: null,
};

export default Amount;
