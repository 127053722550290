import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import MediaQuery from 'react-responsive';
import { Provider } from './ResponsiveTableContext';
import { withLocalization } from '../../utils';
import './ResponsiveTable.scss';

const ResponsiveTable = ({ className, headings, children, t }) => (
  <Provider value={headings}>
    <div className={classNames('responsive-table', className)}>
      <table className="responsive-table__content">
        <MediaQuery minWidth={768}>
          <thead>
            <tr>
              {headings.map(({ label, className }) => (
                <th key={label} className={className}>
                  {t(label)}
                </th>
              ))}
            </tr>
          </thead>
        </MediaQuery>
        <tbody>{children}</tbody>
      </table>
    </div>
  </Provider>
);

ResponsiveTable.propTypes = {
  className: PropTypes.string,
  headings: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      className: PropTypes.string,
    })
  ).isRequired,
  children: PropTypes.node.isRequired,
  t: PropTypes.func.isRequired,
};

ResponsiveTable.defaultProps = {
  className: null,
};

export default withLocalization(ResponsiveTable);
