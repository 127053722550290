import { parse } from 'qs';
import trimStart from 'lodash/trimStart';

/**
 * @param String search
 */
export default (search) => {
  if (typeof search !== 'string' || !search) {
    return {};
  }

  return parse(trimStart(search, '?'));
};
