import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import NavLink from '../NavLink';
import Icon from '../Icon';
import { withLocalization } from '../../utils';
import './NavigationLink.scss';

const NavigationLink = ({ url, icon, label, className, iconClassName, t, onClick, exact }) => (
  <NavLink
    exact={exact}
    to={url}
    className={classNames('navigation-link', className, { 'has-icon': icon })}
    onClick={onClick}
  >
    <If condition={icon}>
      <Icon icon={icon} className={iconClassName} />
    </If>
    {t(label)}
  </NavLink>
);

NavigationLink.propTypes = {
  url: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  label: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  icon: PropTypes.string,
  className: PropTypes.string,
  iconClassName: PropTypes.string,
  onClick: PropTypes.func,
  exact: PropTypes.bool,
};

NavigationLink.defaultProps = {
  icon: null,
  className: null,
  iconClassName: 'navigation-link__icon',
  onClick: null,
  exact: false,
};

export default withLocalization(NavigationLink);
