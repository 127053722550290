import React from 'react';
import PropTypes from 'prop-types';
import MediaQuery from 'react-responsive';
import { Consumer } from './ResponsiveTableContext';
import { withLocalization } from '../../utils';
import './ResponsiveTableCell.scss';

const ResponsiveTableCell = ({ columnKey, children, t }) => (
  <Consumer>
    {(headings) => (
      <td className="responsive-table-cell">
        <MediaQuery maxWidth={767}>
          <div className="responsive-table-cell__label">{t(headings[columnKey].label)}</div>
        </MediaQuery>
        {children}
      </td>
    )}
  </Consumer>
);

ResponsiveTableCell.propTypes = {
  columnKey: PropTypes.number,
  children: PropTypes.node,
  t: PropTypes.func.isRequired,
};

ResponsiveTableCell.defaultProps = {
  columnKey: 0,
  children: null,
};

export default withLocalization(ResponsiveTableCell);
