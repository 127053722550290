import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import Route from '../Route';

class AppRoute extends Component {
  static propTypes = {
    component: PropTypes.func.isRequired,
    layout: PropTypes.func,
    layoutProps: PropTypes.object,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
  };
  static defaultProps = {
    layoutProps: {},
    layout: null,
    location: {},
  };
  static childContextTypes = {
    changeLayoutProps: PropTypes.func,
  };

  static getDerivedStateFromProps(props, state) {
    if (!isEqual(props.location.pathname, state.pathname)) {
      return {
        layoutProps: props.layoutProps,
        pathname: props.location.pathname,
      };
    }

    return null;
  }

  constructor(props) {
    super(props);

    this.state = {
      layoutProps: this.props.layoutProps,
      pathname: this.props.location.pathname,
    };
  }

  getChildContext() {
    return {
      changeLayoutProps: this.handleChangeLayoutProps,
    };
  }

  handleChangeLayoutProps = (layoutProps) => {
    if (layoutProps) {
      this.setState({ layoutProps });
    }
  };

  render() {
    const { layoutProps } = this.state;
    const { component: ChildComponent, layout: Layout, ...rest } = this.props;

    return (
      <Route
        {...rest}
        render={(props) => {
          if (Layout) {
            return (
              <Layout {...layoutProps}>
                <ChildComponent {...props} />
              </Layout>
            );
          }

          return <ChildComponent {...props} {...layoutProps} />;
        }}
      />
    );
  }
}

export default AppRoute;
