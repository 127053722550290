import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from '../Icon';
import './SuccessBlock.scss';

const SuccessBlock = ({ children, className }) => (
  <div className={classNames('success-block', className)}>
    <Icon icon="success" className="success-block__icon" />
    <div className="success-block__text">{children}</div>
  </div>
);

SuccessBlock.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

SuccessBlock.defaultProps = {
  className: null,
};

export default SuccessBlock;
