import delay from './delay';

const waitUntil = async (fn, msDelay = 200, retries = 5) => {
  const result = await fn();

  if (result === false && retries > 0) {
    await delay(msDelay);

    return waitUntil(fn, msDelay * 2, retries - 1);
  }

  return result;
};

export default waitUntil;
