import { CALL_API } from 'redux-api-middleware';
import createReducer from '../../utils/createReducer';
import createRequestAction from '../../utils/createRequestAction';
import { getBrandId } from '../../config';
import { getFingerPrint, getTimeZone } from '../../utils';

const KEY = 'auth';
const AUTHORIZE_USER = `${KEY}/authorize-user`;
const SIGN_IN = createRequestAction(`${KEY}/sign-in`);
const REFRESH_TOKEN = createRequestAction(`${KEY}/refresh-token`);
const LOGOUT = createRequestAction(`${KEY}/logout`);

function signIn({ login, password }) {
  return async (dispatch) =>
    dispatch({
      [CALL_API]: {
        endpoint: '/api/auth/signin',
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          login,
          password,
          device: await getFingerPrint(),
          brandId: getBrandId(),
          timeZone: getTimeZone(),
        }),
        types: [SIGN_IN.REQUEST, SIGN_IN.SUCCESS, SIGN_IN.FAILURE],
      },
    });
}

function cmsSignIn({ login, password, affiliateId }) {
  return async (dispatch) =>
    dispatch({
      [CALL_API]: {
        endpoint: '/api/cms/auth/signin',
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          login,
          promo_code: affiliateId,
          password,
          device: await getFingerPrint(),
          brandId: getBrandId(),
        }),
        types: [SIGN_IN.REQUEST, SIGN_IN.SUCCESS, SIGN_IN.FAILURE],
      },
    });
}

function refreshToken() {
  return (dispatch, getState) => {
    const {
      auth: { token, logged },
    } = getState();

    return dispatch({
      [CALL_API]: {
        method: 'GET',
        endpoint: `/api/auth/token/renew?token=${token}`,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        types: [REFRESH_TOKEN.REQUEST, REFRESH_TOKEN.SUCCESS, REFRESH_TOKEN.FAILURE],
        bailout: !logged,
      },
    });
  };
}

function logout() {
  return (dispatch, getState) => {
    const {
      auth: { token },
    } = getState();

    return dispatch({
      [CALL_API]: {
        endpoint: '/api/auth/logout',
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        types: [LOGOUT.REQUEST, LOGOUT.SUCCESS, LOGOUT.FAILURE],
      },
    });
  };
}

function authorizeUser(auth) {
  return { type: AUTHORIZE_USER, payload: auth };
}

const initialState = {
  logged: false,
  token: null,
  uuid: null,
};
const actionHandlers = {
  [SIGN_IN.SUCCESS]: (state, action) => {
    const { uuid, token } = action.payload;

    return {
      ...state,
      token,
      uuid,
      logged: true,
    };
  },

  [REFRESH_TOKEN.SUCCESS]: (state, action) => ({
    ...state,
    token: action.payload.jwtToken,
  }),
  [AUTHORIZE_USER]: (state, { payload }) => ({
    ...state,
    token: payload.token,
    uuid: payload.uuid,
    logged: true,
  }),
  [LOGOUT.SUCCESS]: () => ({ ...initialState }),
};
const actionTypes = {
  SIGN_IN,
  AUTHORIZE_USER,
  REFRESH_TOKEN,
  LOGOUT,
};
const actionCreators = {
  signIn,
  cmsSignIn,
  logout,
  refreshToken,
  authorizeUser,
};

export { initialState, actionCreators, actionTypes };

export default createReducer(initialState, actionHandlers);
