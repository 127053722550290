import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import { languages } from '../../constants/languages';
import Icon from '../Icon';
import LanguageFlag from '../LanguageFlag';
import './LanguageMenu.scss';

class LanguageMenu extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    match: PropTypes.shape({
      params: PropTypes.object.isRequired,
    }).isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
    }).isRequired,
    logged: PropTypes.bool.isRequired,
    updateLanguage: PropTypes.func.isRequired,
    availableLanguages: PropTypes.arrayOf(PropTypes.string).isRequired,
  };

  getLanguage = (newLang) => {
    const {
      location: { pathname },
      match: {
        params: { lang },
      },
    } = this.props;

    return pathname.replace(lang, newLang);
  };

  handleChange = (languageCode) => () => {
    const { logged, updateLanguage } = this.props;

    if (logged) {
      updateLanguage({ variables: { languageCode } });
    }
  };

  render() {
    const {
      match: {
        params: { lang },
      },
      t,
      availableLanguages,
    } = this.props;

    return (
      <UncontrolledDropdown>
        <DropdownToggle tag="button">
          <LanguageFlag flag={languages[lang].value} />
          <Icon icon="select-arrow" className="language-menu__toggle" />
        </DropdownToggle>
        <DropdownMenu className="language-menu__menu" right>
          <For of={availableLanguages} each="key">
            <DropdownItem key={key} tag="div" className="language-menu__item">
              <Link onClick={this.handleChange(key)} className="language-menu__link" to={this.getLanguage(key)}>
                <LanguageFlag className="language-menu__flag" flag={languages[key].value} />
                {t(`t.language.label.${key}`)}
              </Link>
            </DropdownItem>
          </For>
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  }
}

export default LanguageMenu;
