import React, { Component } from 'react';
import get from 'lodash/get';
import history from '../../router/history';
import PropTypes from '../../constants/prop-types';
import getDeviceType from '../../utils/getDeviceType';
import { AmountTemplate, Button } from '../../components';
import { Modal, ModalBody, ModalHeader } from '../Modal';
import Link from '../Link';
import { SliderArrowNext, SliderArrowPrev } from '../SliderArrows';
import SlickSlider from '../SlickSlider';
import { content } from './constants';
import config from '../../config';
import './PostRegistrationModal.scss';

class PostRegistrationModal extends Component {
  static propTypes = {
    bonusCampaigns: PropTypes.shape({
      bonusCampaigns: PropTypes.shape({
        data: PropTypes.arrayOf(
          PropTypes.shape({
            campaignUUID: PropTypes.string,
            parentCampaignUUID: PropTypes.string,
            description: PropTypes.string,
            shortDescription: PropTypes.string,
            image: PropTypes.string,
          })
        ),
      }),
    }),
    isOpen: PropTypes.bool.isRequired,
    handleCloseModal: PropTypes.func.isRequired,
    optInMutation: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    uuid: PropTypes.string,
    locale: PropTypes.string.isRequired,
    modals: PropTypes.shape({
      warningModal: PropTypes.modalType,
    }).isRequired,
  };

  static defaultProps = {
    uuid: '',
    bonusCampaigns: { loading: false },
  };

  get registrationBonusCampaign() {
    const {
      bonusCampaigns: { bonusCampaigns },
    } = this.props;

    return get(bonusCampaigns, 'data[0]');
  }

  componentDidUpdate({ bonusCampaigns: { loading: prevLoading } }) {
    const {
      bonusCampaigns: { loading },
    } = this.props;
    const { registrationBonusCampaign } = this;

    if (prevLoading && !loading && !registrationBonusCampaign) {
      this.handleClose();
    }
  }

  handleClose = () => {
    const {
      handleCloseModal,
      modals: { warningModal },
    } = this.props;

    handleCloseModal();

    if (config.showWarningsForPlayer) {
      warningModal.show({ title: 't.warning.modal.title', content: 't.warning.modal.content' });
    }
  };

  handleOptIn = async () => {
    const { optInMutation, uuid: playerUUID, locale } = this.props;
    const {
      registrationBonusCampaign: { campaignUUID, parentCampaignUUID },
    } = this;

    await optInMutation({
      variables: {
        campaignUUID,
        parentCampaignUUID,
        playerUUID,
        deviceType: getDeviceType(),
      },
    });
    this.handleClose();
    history.push({ pathname: `/${locale}/player/cashbox`, state: { modal: true } });
  };

  render() {
    const { isOpen, t, locale } = this.props;
    const { registrationBonusCampaign } = this;

    if (!registrationBonusCampaign) {
      return null;
    }

    const { image, title, shortDescription } = registrationBonusCampaign;

    return (
      <Modal isOpen={isOpen} toggle={this.handleClose} backdrop="static">
        <ModalHeader title={t('t.postreg_modal.title')} toggle={this.handleClose} />
        <ModalBody className="post-registration">
          <SlickSlider
            arrows
            prevArrow={<SliderArrowPrev />}
            nextArrow={<SliderArrowNext />}
            dots
            dotsClass="post-registration__dots"
            fade
            speed={700}
          >
            <div>
              <div className="row no-gutters">
                <div className="col-10 col-md-5 post-registration__info">
                  <h3 className="post-registration__title">{t('t.postreg_modal.first_slide.title')}</h3>
                  <p className="post-registration__description">
                    <AmountTemplate value={shortDescription || ''} renderAsHtml />
                  </p>
                  <div className="post-registration__image-wrapper">
                    <img className="img-fluid" src={image} alt={title} />
                  </div>
                  <Button type="button" onClick={this.handleOptIn} className="post-registration__button" block>
                    {t('t.postreg_modal.first_slide.action')}
                  </Button>
                  <If condition={config.showWarningsForPlayer}>
                    <div className="post-registration__warning">{t('t.warning.postreg_modal.message')}</div>
                  </If>
                  <Link
                    className="post-registration__link"
                    onClick={this.handleClose}
                    to={{ pathname: `/${locale}/player/bonuses`, state: { modal: true } }}
                  >
                    {t('t.postreg_modal.first_slide.link')}
                  </Link>
                </div>
                <div className="d-none d-md-block col-md-6">
                  <img src="/img/post-registration-modal/first-slide-bg.png" alt={title} />
                </div>
              </div>
            </div>
            {content.map(({ slideTitle, description, slideImage, buttonLabel, background }) => (
              <div key={slideImage}>
                <div className="row no-gutters">
                  <div className="col-10 col-md-6 post-registration__info pl-md-4 pr-md-3">
                    <h3 className="post-registration__title">{t(slideTitle)}</h3>
                    <p className="post-registration__description">{t(description)}</p>
                    <div className="post-registration__image-wrapper">
                      <img className="img-fluid" src={slideImage} alt={t(slideTitle)} />
                    </div>
                    <Button
                      tag={Link}
                      className="post-registration__button"
                      block
                      onClick={this.handleClose}
                      to={{ pathname: `/${locale}/player/cashbox`, state: { modal: true } }}
                    >
                      {t(buttonLabel)}
                    </Button>
                  </div>
                  <div className="d-none d-md-block col">
                    <img src={background} alt={t(slideTitle)} />
                  </div>
                </div>
              </div>
            ))}
          </SlickSlider>
        </ModalBody>
      </Modal>
    );
  }
}

export default PostRegistrationModal;
