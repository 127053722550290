import get from 'lodash/get';

export default (content) => {
  const paramsValue = get(content.match(/var params = {([^}]+)}/), '[1]');

  if (!paramsValue) {
    return null;
  }

  return eval(`({${paramsValue}})`); // eslint-disable-line
};
