import get from 'lodash/get';
import { gameCategoriesTag } from '../constants/games';

const renderGameCategoriesArray = (gameCategories) =>
  get(gameCategories, 'gameNgCategories.value', []).map((category) => ({
    label: `t.tag.${gameCategoriesTag}.${category}`,
    url: `/games/${category}`,
    key: category,
    value: category,
  }));

export default renderGameCategoriesArray;
