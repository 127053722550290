const removeDuplicates = (arr) =>
  arr.reduce((result, item) => {
    const existItemIndex = result.findIndex((i) => i.campaignUUID === item.campaignUUID);

    if (existItemIndex !== -1) {
      if (result[existItemIndex].bonusUUID) {
        return result;
      } else if (item.bonusUUID) {
        return [...result].splice(existItemIndex, 1, item);
      }
    }

    return [...result, item];
  }, []);

export default removeDuplicates;
