import trimEnd from 'lodash/trimEnd';
import { availableLanguages } from '../constants/languages';

function getUrlWithLang(url, lang) {
  return url.match(new RegExp(`^/(${availableLanguages.join('|')})(/|$)`))
    ? url.replace(new RegExp(`^/(${availableLanguages.join('|')})(/|$)`), `/${lang}/`)
    : trimEnd(`/${lang}${url}`, ['/', '\\']);
}

export { getUrlWithLang };
