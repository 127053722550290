import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { i18nState } from 'redux-i18n';
import affiliate from '../redux/modules/affiliate';
import auth from '../redux/modules/auth';
import theme from '../redux/modules/theme';
import promo from '../redux/modules/promo';

export const makeRootReducer = (asyncReducers) =>
  combineReducers({
    affiliate,
    auth,
    promo,
    theme,
    form: formReducer,
    i18nState,
    ...asyncReducers,
  });

export const injectReducer = (store, { key, reducer }) => {
  if (Object.hasOwnProperty.call(store.asyncReducers, key)) return;

  store.asyncReducers[key] = reducer;
  store.replaceReducer(makeRootReducer(store.asyncReducers));
};

export default makeRootReducer;
