import countries from 'country-list';
import { themes } from './constants/themes';

const countryList = countries.getCodeList();

const config = {
  affiliateUrl: '',
  cmsUrl: '',
  webSocketUrl: '',
  serverTime: '',
  socialAuthUrl: '',
  brandId: '',
  defaultLanguage: 'en',
  baseCurrency: 'EUR',
  middlewares: {
    unauthorized: [401],
    persist: { whitelist: ['affiliate', 'auth', 'theme', 'promo'] },
  },
  sportsBookEnabled: false,
  theme: themes.DARK,
  domain: '',
  showWarningsForPlayer: false,
  paymentMethodsByGeo: {},
  availableLanguages: ['en', 'pl', 'tr', 'es', 'de', 'fi', 'no', 'pt'],
  additionalLanguages: [],
  hiddenCurrencies: [],
  cardMethods: [],
  enableSocialAuth: false,
  ...window.appConfig,
};

config.middlewares.persist.keyPrefix = `${config.brandId}:`;

function getDomain() {
  return config.domain || window.location.hostname;
}

function getAffiliateUrl() {
  return config.affiliateUrl;
}

function getSocialAuthUrl() {
  return config.socialAuthUrl || `/api/social-auth-ng`;
}

function getEnableSocialAuth() {
  return config.enableSocialAuth;
}

function getWebSocketUrl() {
  return `${window.location.origin.replace(/^http?/, 'ws')}/socket`;
}

function getBrandId() {
  return config.brandId;
}

function getCountryName(code) {
  return countryList[code.toLowerCase()];
}

function getTheme() {
  return config.theme || themes.DARK;
}

function getBaseCurrency() {
  return config.baseCurrency;
}

function getHiddenCurrencies() {
  return config.hiddenCurrencies;
}

const completeProfileCurrencies = [
  'EUR',
  'USD',
  'TRY',
  'PLN',
  'ZAR',
  'ARS',
  'MXN',
  'PEN',
  'CLP',
  'NOK',
  'BRL',
  'CAD',
  'AUD',
  'CHF',
  'CZK',
  'JPY',
];

export {
  getSocialAuthUrl,
  getWebSocketUrl,
  getBrandId,
  getCountryName,
  getAffiliateUrl,
  countryList,
  getTheme,
  getDomain,
  getBaseCurrency,
  getHiddenCurrencies,
  completeProfileCurrencies,
  getEnableSocialAuth,
};

export default config;
