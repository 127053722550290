import config from '../config';

const EN = 'en';
const RU = 'ru';
const PL = 'pl';
const TR = 'tr';
const ES = 'es';
const DE = 'de';
const PT = 'pt';
const NO = 'no';
const FI = 'fi';

const languages = {
  [EN]: {
    alias: 'english',
    value: 'en',
  },
  [RU]: {
    alias: 'russian',
    value: 'ru',
  },
  [PL]: {
    alias: 'polish',
    value: 'pl',
  },
  [TR]: {
    alias: 'turkish',
    value: 'tr',
  },
  [ES]: {
    alias: 'spanish',
    value: 'es',
  },
  [DE]: {
    alias: 'deutsch',
    value: 'de',
  },
  [NO]: {
    alias: 'norwegian',
    value: 'no',
  },
  [FI]: {
    alias: 'finnish',
    value: 'fi',
  },
  [PT]: {
    alias: 'portugal',
    value: 'pt',
  },
};

const { availableLanguages, defaultLanguage, additionalLanguages } = config;

export { availableLanguages, defaultLanguage, languages, additionalLanguages };
