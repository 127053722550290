const checkValidRegExp = (regExp) => {
  try {
    new RegExp(regExp);

    return true;
  } catch (e) {
    return false;
  }
};

export default checkValidRegExp;
