import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withAppTheme } from '../../utils';
import './Divider.scss';

const Divider = ({ type, className, theme }) => (
  <div
    className={classNames('divider', type, className)}
    style={{ backgroundImage: `url(/img/themes/${theme}/divider-${type}.svg)` }}
  />
);

Divider.propTypes = {
  theme: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['colored', 'black', 'grey', 'light']).isRequired,
  className: PropTypes.string,
};
Divider.defaultProps = {
  className: null,
};

export default withAppTheme(Divider);
