import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { UncontrolledTooltip } from 'reactstrap';

const ElementWithUncontrolledTooltip = (props) => {
  const { id, containerId, children, tooltipContent, ...rest } = props;

  return (
    <Fragment>
      {React.cloneElement(children, { id })}
      <UncontrolledTooltip {...rest} target={id} container={containerId}>
        {tooltipContent}
      </UncontrolledTooltip>
    </Fragment>
  );
};

ElementWithUncontrolledTooltip.propTypes = {
  id: PropTypes.string.isRequired,
  containerId: PropTypes.string,
  children: PropTypes.node.isRequired,
  tooltipContent: PropTypes.node.isRequired,
};

ElementWithUncontrolledTooltip.defaultProps = {
  containerId: null,
};

export default ElementWithUncontrolledTooltip;
