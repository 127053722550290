import { Component } from 'react';
import PropTypes from 'prop-types';
import withAppTheme from '../utils/withAppTheme';

class ThemeProvider extends Component {
  static propTypes = {
    theme: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
  };

  componentDidMount() {
    const { theme } = this.props;
    const themeClassName = `theme-${theme}`;

    if (!document.body.classList.contains(themeClassName)) {
      document.body.classList.add(themeClassName);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.theme !== prevProps.theme) {
      if (document.body.classList.contains(`theme-${prevProps.theme}`)) {
        document.body.classList.remove(`theme-${prevProps.theme}`);
        document.body.classList.add(`theme-${this.props.theme}`);
      }
    }
  }

  componentWillUnmount() {
    const { theme } = this.props;
    const themeClassName = `theme-${theme}`;

    if (!document.body.classList.contains(themeClassName)) {
      document.body.classList.remove(themeClassName);
    }
  }

  render() {
    return this.props.children;
  }
}

export default withAppTheme(ThemeProvider);
