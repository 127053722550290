import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './LanguageFlag.scss';

const LanguageFlag = ({ flag, className }) => <span className={classNames('language-flag', flag, className)} />;

LanguageFlag.propTypes = {
  flag: PropTypes.string.isRequired,
  className: PropTypes.string,
};

LanguageFlag.defaultProps = {
  className: null,
};

export default LanguageFlag;
