import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { compose } from 'redux';
import onClickOutside from 'react-onclickoutside';
import Amount from '../Amount';
import Icon from '../Icon';
import { withLocalization, withModals } from '../../utils';
import ErrorModal from '../ErrorModal';
import isMobile from '../../utils/isMobile';
import { modes, restrictedGameModalOptions } from '../../constants/games';
import Button from '../Button';
import GameItemImage from './GameItemImage';
import history from '../../router/history';
import './GameItem.scss';

class GameItem extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    internalGameId: PropTypes.string.isRequired,
    modals: PropTypes.shape({
      error: PropTypes.shape({
        show: PropTypes.func,
      }),
    }).isRequired,
    fullGameName: PropTypes.string,
    image: PropTypes.string,
    isFavorite: PropTypes.bool,
    logged: PropTypes.bool,
    restricted: PropTypes.bool,
    removeFromFavorites: PropTypes.func,
    addToFavorites: PropTypes.func,
    label: PropTypes.node,
    labelColor: PropTypes.oneOf(['new', 'jackpot', 'gameof']),
    jackpot: PropTypes.string,
    hasDemoMode: PropTypes.bool,
    slug: PropTypes.string.isRequired,
    providerAlias: PropTypes.string.isRequired,
    className: PropTypes.string,
    onGameButtonClick: PropTypes.func,
    locale: PropTypes.string.isRequired,
  };
  static defaultProps = {
    fullGameName: '',
    image: '',
    isFavorite: false,
    restricted: false,
    logged: false,
    removeFromFavorites: null,
    addToFavorites: null,
    label: null,
    labelColor: null,
    jackpot: null,
    hasDemoMode: false,
    className: null,
    onGameButtonClick: null,
  };

  state = {
    overlayIsActive: false,
  };

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;

    if (this.overlayTimeout) {
      clearTimeout(this.overlayTimeout);
    }
  }

  toggleFavorite = () => {
    const { internalGameId, isFavorite, addToFavorites, removeFromFavorites } = this.props;

    if (isFavorite) {
      removeFromFavorites({ internalGameId });
    } else {
      addToFavorites({ internalGameId });
    }
  };

  handleActionClick = (mode) => () => {
    const {
      restricted,
      modals: { error },
      onGameButtonClick,
      locale,
      providerAlias,
      slug: gameAlias,
    } = this.props;

    if (restricted) {
      return error.show(restrictedGameModalOptions);
    }

    if (typeof onGameButtonClick === 'function') {
      onGameButtonClick();
    }

    history.push(`/${locale}/game/${providerAlias}/${gameAlias}/${mode}`);
  };

  handleClickOutside = () => {
    if (isMobile.any() && this.state.overlayIsActive) {
      this.setState({ overlayIsActive: false });
    }
  };

  toggleOverlayState = () => {
    if (isMobile.any()) {
      if (this.state.overlayIsActive) {
        this.handleClickOutside();
      } else {
        this.overlayTimeout = setTimeout(() => {
          if (this.mounted) {
            this.setState({ overlayIsActive: true });
          }
        }, 50);
      }
    }
  };

  mounted = false;
  overlayTimeout = null;

  render() {
    const {
      t,
      fullGameName,
      hasDemoMode,
      image,
      isFavorite,
      logged,
      label,
      labelColor,
      jackpot,
      className,
      addToFavorites,
      removeFromFavorites,
    } = this.props;
    const { overlayIsActive } = this.state;

    return (
      <div className={classNames('game-item', className)} onClick={this.toggleOverlayState}>
        <GameItemImage src={image} alt={fullGameName} />
        <If condition={label}>
          <span className={`game-item__label ${labelColor}`}>{label}</span>
        </If>
        <If condition={jackpot}>
          <Amount tag="div" className="game-item__jackpot" amount={jackpot} currency="RUB" />
        </If>
        <div className={classNames('game-item__overlay', { open: overlayIsActive })}>
          <div className="game-item__name">{fullGameName}</div>
          <div className="row no-gutters justify-content-center my-auto">
            <div className="col-6">
              <Button type="button" className="game-item__action" onClick={this.handleActionClick(modes.REAL)}>
                {t('t.play')}
              </Button>
            </div>
            <If condition={hasDemoMode}>
              <div className="col-6 pl-2">
                <Button
                  type="button"
                  color={Button.colors.outlined}
                  className="game-item__action"
                  onClick={this.handleActionClick(modes.DEMO)}
                >
                  {t('t.demo')}
                </Button>
              </div>
            </If>
          </div>
          <If condition={logged && addToFavorites && removeFromFavorites}>
            <div className="text-right">
              <Icon
                tag="button"
                type="button"
                icon="favorite"
                className={classNames('game-item__favorite', { active: isFavorite })}
                onClick={this.toggleFavorite}
              />
            </div>
          </If>
        </div>
      </div>
    );
  }
}

export default compose(
  withLocalization,
  withModals({
    error: ErrorModal,
  }),
  onClickOutside
)(GameItem);
