import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import './SlickSlider.scss';

const SlickSlider = ({ innerRef, children, ...props }) => (
  <Slider draggable={false} ref={innerRef} {...props}>
    {children}
  </Slider>
);

SlickSlider.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  arrows: PropTypes.bool,
  autoplay: PropTypes.bool,
  autoplaySpeed: PropTypes.number,
  dots: PropTypes.bool,
  dotsClass: PropTypes.string,
  slidesToShow: PropTypes.number,
  slidesToScroll: PropTypes.number,
  fade: PropTypes.bool,
  swipe: PropTypes.bool,
  speed: PropTypes.number,
  focusOnSelect: PropTypes.bool,
  prevArrow: PropTypes.element,
  nextArrow: PropTypes.element,
  responsive: PropTypes.array,
  centerMode: PropTypes.bool,
  centerPadding: PropTypes.string,
  variableWidth: PropTypes.bool,
  initialSlide: PropTypes.number,
  innerRef: PropTypes.func,
  infinite: PropTypes.bool,
  beforeChange: PropTypes.func,
  afterChange: PropTypes.func,
  pauseOnHover: PropTypes.bool,
  vertical: PropTypes.bool,
};

SlickSlider.defaultProps = {
  className: null,
  arrows: false,
  autoplay: false,
  autoplaySpeed: 3600,
  dots: false,
  dotsClass: null,
  slidesToShow: 1,
  slidesToScroll: 1,
  fade: false,
  swipe: true,
  speed: 500,
  focusOnSelect: false,
  prevArrow: null,
  nextArrow: null,
  responsive: null,
  centerMode: false,
  centerPadding: '50px',
  variableWidth: false,
  initialSlide: 0,
  innerRef: null,
  infinite: true,
  beforeChange: null,
  afterChange: null,
  pauseOnHover: true,
  vertical: false,
};

export default SlickSlider;
