import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import Preloader from '../../components/Preloader';
import { parseJson } from '../../utils';

class Localization extends Component {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.object.isRequired,
    }).isRequired,
    setLanguage: PropTypes.func.isRequired,
    setTranslations: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
    translations: PropTypes.objectOf(PropTypes.object).isRequired,
  };

  state = {
    isTranslationsLoaded: false,
  };

  async componentDidMount() {
    const {
      setLanguage,
      setTranslations,
      match: {
        params: { lang: language },
      },
    } = this.props;
    const translations = await this.getTranslations(language);

    setTranslations(translations, { language, preserveExisting: true });
    moment.updateLocale('pl', {
      months: [
        'Styczeń',
        'Luty',
        'Marzec',
        'Kwiecień',
        'Maj',
        'Czerwiec',
        'Lipiec',
        'Sierpień',
        'Wrzesień',
        'Październik',
        'Listopad',
        'Grudzień',
      ],
    });
    moment.locale(language);
    setLanguage(language);
    this.setState({ isTranslationsLoaded: true });
  }

  async componentDidUpdate(prevProps) {
    const {
      setTranslations,
      setLanguage,
      match: {
        params: { lang: nextLang },
      },
      translations,
    } = this.props;
    const {
      match: {
        params: { lang: prevLang },
      },
    } = prevProps;

    if (prevLang !== nextLang) {
      if (!translations[nextLang]) {
        const cmsTranslations = await this.getTranslations(nextLang);

        setTranslations(cmsTranslations, { language: nextLang, preserveExisting: true });
      }

      moment.locale(nextLang);
      setLanguage(nextLang);
    }
  }

  getTranslations = async (lang) => {
    const translationsArray = await fetch(`/api/cms/translations/locale-${lang}.json`)
      .then((response) => response.text())
      .then((response) => parseJson(response))
      .then((response) =>
        !isEmpty(response)
          ? Object.keys(response).map((item) => ({
              key: item,
              value: response[item],
            }))
          : []
      );

    return translationsArray.reduce((acc, { key, value }) => ({ ...acc, [key]: value }), {});
  };

  render() {
    const { children } = this.props;
    const { isTranslationsLoaded } = this.state;

    if (!isTranslationsLoaded) {
      return <Preloader />;
    }

    return children;
  }
}

export default Localization;
