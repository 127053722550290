function hyphenToCamelcase(str) {
  let result = '';
  let upper = false;

  for (let i = 0; i < str.length; i++) {
    let c = str[i];

    if (c === '-') {
      upper = true;
      continue;
    }

    if (upper) {
      c = c.toUpperCase();
      upper = false;
    }

    result += c;
  }

  return result;
}

function convertKey(key) {
  let res = hyphenToCamelcase(key);

  if (key.indexOf('-ms-') === 0) {
    res = res[0].toLowerCase() + res.slice(1);
  }

  return res;
}

export default function (styleStr) {
  return styleStr
    .split(';')
    .reduce((res, token) => {
      if (token.slice(0, 7) === 'base64,') {
        res[res.length - 1] += `;${token}`;
      } else {
        res.push(token);
      }
      return res;
    }, [])
    .reduce((obj, str) => {
      const tokens = str.split(':');
      const key = tokens[0].trim();

      if (key) {
        const value = tokens.slice(1).join(':').trim();
        obj[convertKey(key)] = value;
      }

      return obj;
    }, {});
}
