import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ReactSelect, { components } from 'react-select';
import Radio from '../Radio';
import CustomScroll from '../CustomScroll';
import './CustomSelect.scss';

const className = 'custom-select-field';

const customStyles = {
  noOptionsMessage: (base) => ({
    ...base,
    fontSize: '14px',
  }),
  option: () => {},
};

const DropdownIndicator = (props) => {
  const {
    selectProps: { menuIsOpen },
  } = props;

  return (
    <components.DropdownIndicator {...props}>
      <div className={classNames(`${className}__arrow`, { rotated: menuIsOpen })} />
    </components.DropdownIndicator>
  );
};

const IndicatorSeparator = () => <div className={`${className}__indicator-separator`} />;

class CustomSelect extends PureComponent {
  static propTypes = {
    name: PropTypes.string,
    options: PropTypes.array.isRequired,
    disabled: PropTypes.bool,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    searchable: PropTypes.bool,
    value: PropTypes.string,
    noResultsText: PropTypes.string,
    hasDanger: PropTypes.bool,
    maxMenuHeight: PropTypes.number,
    optionsAsRadios: PropTypes.bool,
    filterView: PropTypes.bool,
  };
  static defaultProps = {
    name: null,
    disabled: false,
    placeholder: null,
    searchable: false,
    onChange: null,
    value: null,
    noResultsText: 'Nothing found',
    hasDanger: false,
    maxMenuHeight: 200,
    optionsAsRadios: false,
    filterView: false,
  };

  renderMenuList = (props) => {
    const { maxMenuHeight } = this.props;

    return <CustomScroll maxHeight={maxMenuHeight}>{props.children}</CustomScroll>;
  };

  renderOption = (props) => {
    const { optionsAsRadios } = this.props;
    const {
      label,
      isSelected,
      innerProps: { onClick },
    } = props;

    return (
      <Choose>
        <When condition={optionsAsRadios}>
          <Radio tag="div" className={`${className}__option`} label={label} active={isSelected} onClick={onClick} />
        </When>
        <Otherwise>
          <div className={`${className}__default-option`}>
            <components.Option {...props} />
          </div>
        </Otherwise>
      </Choose>
    );
  };

  renderInput = (props) => {
    const { placeholder } = this.props;
    const {
      isHidden,
      value,
      selectProps: { menuIsOpen },
    } = props;

    return (
      <Fragment>
        <If condition={!isHidden && menuIsOpen && placeholder && !value.length}>
          <div className={`${className}__custom-placeholder`}>{placeholder}</div>
        </If>
        <components.Input {...props} />
      </Fragment>
    );
  };

  render() {
    const {
      searchable,
      name,
      options,
      disabled,
      placeholder,
      onChange,
      value,
      noResultsText,
      hasDanger,
      filterView,
    } = this.props;

    const selectedOption = options.find((option) => option.value === value);

    return (
      <ReactSelect
        styles={customStyles}
        name={name}
        className={classNames(className, {
          'has-danger': hasDanger,
          'filter-view': filterView,
          searchable: searchable && placeholder,
        })}
        classNamePrefix={className}
        options={options}
        onChange={onChange}
        value={selectedOption}
        placeholder={placeholder}
        isSearchable={searchable}
        noOptionsMessage={() => noResultsText}
        isDisabled={disabled}
        openMenuOnFocus
        tabSelectsValue={false}
        components={{
          MenuList: this.renderMenuList,
          IndicatorSeparator,
          DropdownIndicator,
          Option: this.renderOption,
          Input: this.renderInput,
        }}
      />
    );
  }
}

export default CustomSelect;
