import React, { Component, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { Redirect } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from '../../../constants/prop-types';
import ShortPreloader from '../../../components/ShortPreloader';
import { parseSportsBookParams } from '../utils';
import getDeviceType from '../../../utils/getDeviceType';
import history from '../../../router/history';
import { waitUntil } from '../../../utils';
import './SportsBookView.scss';

const REQUIRED_EXTERNAL_SCRIPTS = {
  DESKTOP: () => typeof get(window, 'SportFrame.frame') === 'function',
  MOBILE: () => typeof get(window, 'Bootstrapper.bootMobile') === 'function',
};

const ADDITIONAL_SPORTS_BOOK_DESKTOP_PARAMS = {
  login: 'SportFrame.login',
  registration: 'SportFrame.registration',
  unHideOverflow: true,
};

class SportsBookView extends Component {
  static propTypes = {
    lang: PropTypes.string.isRequired,
    playerUUID: PropTypes.string,
    startGame: PropTypes.func.isRequired,
    stopGame: PropTypes.func.isRequired,
    profile: PropTypes.shape({
      player: PropTypes.shape({
        data: PropTypes.shape({
          totalBalance: PropTypes.money,
        }),
      }),
      loading: PropTypes.bool.isRequired,
    }),
    profileInfo: PropTypes.shape({
      player: PropTypes.shape({
        data: PropTypes.shape({
          completed: PropTypes.bool,
        }),
      }),
      loading: PropTypes.bool.isRequired,
    }),
    match: PropTypes.shape({
      params: PropTypes.shape({
        type: PropTypes.oneOf(['pre-match', 'live']).isRequired,
      }).isRequired,
    }).isRequired,
    logged: PropTypes.bool.isRequired,
  };

  static contextTypes = {
    mainModals: PropTypes.shape({
      errorModal: PropTypes.modalType,
    }),
  };

  static defaultProps = {
    playerUUID: null,
    profile: {
      loading: false,
    },
    profileInfo: {
      loading: false,
    },
  };

  state = {
    data: null,
    error: null,
    loading: true,
    url: null,
    params: {},
  };

  componentDidMount() {
    const { profile, logged } = this.props;

    this.mounted = true;

    if (!profile.loading) {
      this.startGame();
    }

    if (!logged) {
      window.addEventListener('message', this.handlePostMessages, false);
    }
  }

  componentDidUpdate({ lang: prevLang, profile: prevProfile }) {
    const { lang, profile } = this.props;

    if ((!profile.loading && prevProfile.loading) || lang !== prevLang) {
      this.startGame();
    }
  }

  async componentWillUnmount() {
    this.mounted = false;

    await this.stopGame();

    window.removeEventListener('message', this.handlePostMessages);

    if (getDeviceType() === 'DESKTOP') {
      delete window.SportFrame;
    } else {
      delete window.Bootstrapper;
    }
  }

  get desktopParams() {
    const { params } = this.state;

    return JSON.stringify(Object.entries(params)).slice(1, -1);
  }

  get mobileParams() {
    const { params } = this.state;

    return JSON.stringify(params);
  }

  handlePostMessages = (event) => {
    const { lang } = this.props;
    const { registration } = event.data;

    if (registration) {
      const { location, push } = history;

      push({
        pathname: `/${lang}/sign-up`,
        state: {
          modal: true,
          prevLocation: location,
        },
      });
    }
  };

  mounted = false;

  startGame = () => {
    const {
      startGame,
      match: {
        params: { type },
      },
      lang,
    } = this.props;

    return startGame().then(async (response) => {
      const { data, error } = get(response, 'data.game.start', this.state);
      const nextState = { loading: false, data, error };

      const contentResponse = await fetch(data.gameSessionURL);

      const content = await contentResponse.text();
      const scriptUrlMatches = content.match(/src='([^']+)'/);

      if (scriptUrlMatches) {
        nextState.url = scriptUrlMatches[1];
      }

      if (this.mounted) {
        this.setState(nextState, async () => {
          const scriptIsLoaded = await waitUntil(REQUIRED_EXTERNAL_SCRIPTS[getDeviceType()], 100, 10);

          if (scriptIsLoaded && this.mounted) {
            let params = parseSportsBookParams(content);

            if (getDeviceType() === 'DESKTOP') {
              params = { ...params, ...ADDITIONAL_SPORTS_BOOK_DESKTOP_PARAMS };

              window.SportFrame.login = () => history.push(`/${lang}/sign-in`);
              window.SportFrame.registration = () => history.push(`/${lang}/sign-up`);
            }

            if (type === 'live') {
              params.currentPage = 'Overview';
            }

            if (this.mounted) {
              this.setState({ params });
            }
          }
        });
      }
    });
  };

  stopGame = async () => {
    const { playerUUID, stopGame } = this.props;
    const { data } = this.state;

    if (data && data.gameSessionId && playerUUID) {
      await stopGame({ variables: { sessionId: data.gameSessionId } });
    }
  };

  render() {
    const {
      match: {
        params: { type: pageType },
      },
      profile,
      profileInfo,
      lang,
      logged,
    } = this.props;
    const { loading, error, url, params } = this.state;

    if (loading || profile.loading || profileInfo.loading) {
      return <ShortPreloader />;
    }

    if (error) {
      return <div>{error}</div>;
    }

    const profileCompleted = get(profileInfo, 'player.data.completed', false);
    const {
      location: { pathname },
    } = history;

    if (logged && !profileCompleted) {
      return <Redirect to={{ pathname: `/${lang}/profile-form`, state: { returnUrl: pathname } }} />;
    }

    return (
      <Fragment>
        <Helmet>
          <script type="text/javascript" src={url} />
          <If condition={!isEmpty(params)}>
            <script>
              <Choose>
                <When condition={getDeviceType() === 'DESKTOP'}>{`SportFrame.frame([${this.desktopParams}]);`}</When>
                <Otherwise>{`Bootstrapper.bootMobile(${this.mobileParams}, { name: 'Mobile' })`}</Otherwise>
              </Choose>
            </script>
          </If>
        </Helmet>
        <div id="egamings_container">
          <If condition={isEmpty(params)}>
            <ShortPreloader />
          </If>
          <div className={classNames({ 'sportsbook-live-view': pageType === 'live' })} id="sport_div_iframe" />
        </div>
      </Fragment>
    );
  }
}

export default SportsBookView;
