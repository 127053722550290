import React from 'react';
import PropTypes from 'prop-types';
import ContentDivider from '../../../ContentDivider';
import './EventStatsItem.scss';

const EventStatsItem = ({ label, children, isLast }) => (
  <div className="d-flex">
    <ContentDivider />
    <div className="event-stats-item">
      <div className="event-stats-item__label">{label}:</div>
      {children}
    </div>
    <If condition={isLast}>
      <ContentDivider />
    </If>
  </div>
);

EventStatsItem.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  isLast: PropTypes.bool,
};

EventStatsItem.defaultProps = {
  isLast: false,
};

export default EventStatsItem;
