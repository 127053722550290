import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withAppTheme from '../../utils/withAppTheme';
import ShortPreloader from '../ShortPreloader';
import './Preloader.scss';

const Preloader = ({ className, theme, dotsHidden, imageClassName }) => (
  <div className={classNames('preloader', className)}>
    <img className={classNames('img-fluid', imageClassName)} src={`/img/themes/${theme}/preloader.gif`} alt="cat" />
    <If condition={!dotsHidden}>
      <ShortPreloader className="preloader__dots" />
    </If>
  </div>
);

Preloader.propTypes = {
  className: PropTypes.string,
  imageClassName: PropTypes.string,
  theme: PropTypes.string.isRequired,
  dotsHidden: PropTypes.bool,
};

Preloader.defaultProps = {
  className: null,
  imageClassName: null,
  dotsHidden: false,
};

export default withAppTheme(Preloader);
