import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Logo from '../../components/Logo';
import { withLocalization } from '../../utils';
import ButtonBack from '../../components/ButtonBack';
import SupportButton from '../../components/SupportButton';
import './NotFound.scss';

const bodyNotFoundClass = 'not-found-view';

class NotFound extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
  };

  componentDidMount() {
    if (!document.body.classList.contains(bodyNotFoundClass)) {
      document.body.classList.add(bodyNotFoundClass);
    }
  }

  componentWillUnmount() {
    if (document.body.classList.contains(bodyNotFoundClass)) {
      document.body.classList.remove(bodyNotFoundClass);
    }
  }

  render() {
    const { t } = this.props;

    return (
      <div className="not-found">
        <Logo to="/" />
        <img className="not-found__image" src="/img/notFound.svg" alt="" />
        <div className="not-found__text">
          <span>{t('t.not_found.oh.text')}</span> {t('t.not_found.something_wrong.text')}
        </div>
        <SupportButton className="not-found__support" label={t('t.contact.support')} />
        <ButtonBack link="/" />
      </div>
    );
  }
}

export default withLocalization(NotFound);
