import React from 'react';
import { Redirect } from 'react-router-dom';
import Switch from '../Switch';

const NotFoundRedirect = () => (
  <Switch>
    <Redirect to={{ query: { isNotFound: true } }} />
  </Switch>
);

export default NotFoundRedirect;
