import { parse } from 'qs';
import trimStart from 'lodash/trimStart';

const tags = [
  {
    name: 'promo',
    validate: (value) => value && typeof value === 'string' && value.length >= 30,
    format: (value, state) => [
      ...state,
      { name: 'aff_landing', value: value.substr(0, 4) },
      { name: 'aff_token', value: value.substr(4, 10) },
      { name: 'promo_code', value: value.substr(14, 16) },
    ],
  },
  { name: 'utm_medium' },
  { name: 'utm_term' },
  { name: 'utm_campaign' },
  { name: 'utm_source' },
  { name: 'pid' },
  { name: 'utm_content' },
  { name: 'saff_id' },
  { name: 'clickid' },
  { name: 'm_click' },
];

const buildAffiliateParams = (params, config) =>
  Object.keys(params).reduce((res, key) => {
    const value = params[key];
    const paramConfig = config.find((item) => item.name === key);

    if (!paramConfig) {
      return res;
    }

    const nextParam = {
      name: key,
      value,
    };

    if (typeof paramConfig.validate === 'function') {
      nextParam.invalid = !paramConfig.validate(value);
    }

    if (!nextParam.invalid && typeof paramConfig.format === 'function') {
      return paramConfig.format(nextParam.value, res);
    }

    return [...res, nextParam];
  }, []);

export default (queryString) => {
  if (!queryString) {
    return {};
  }

  const params = parse(trimStart(queryString, '?'));
  const affiliateParams = buildAffiliateParams(params, tags);

  if (document.referrer) {
    affiliateParams.http_referer = document.referrer;
  }

  if (affiliateParams.some((param) => param.invalid)) {
    return {};
  }

  const result = affiliateParams.reduce((res, { name, value }) => ({ ...res, [name]: value }), {});

  return !result.promo_code
    ? {}
    : {
        affiliateId: result.promo_code,
        btag: JSON.stringify(result),
      };
};
