import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { actionCreators as themeActionCreators } from '../redux/modules/theme';

const mapStateToProps = ({ theme }) => ({ theme });
const mapActions = {
  toggleTheme: themeActionCreators.toggleTheme,
};

function withAppTheme(WrappedComponent) {
  const AppTheme = (props) => <WrappedComponent {...props} />;

  AppTheme.propTypes = {
    theme: PropTypes.string.isRequired,
    toggleTheme: PropTypes.func.isRequired,
  };

  return connect(mapStateToProps, mapActions)(AppTheme);
}

export default withAppTheme;
