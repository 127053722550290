import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon';

const SliderArrowNext = ({ onClick, outline }) => (
  <Choose>
    <When condition={outline}>
      <Icon
        tag="button"
        type="button"
        onClick={onClick}
        icon="slider-arrow-right"
        className="slider-arrows-outline slider-arrows-outline--right"
      />
    </When>
    <Otherwise>
      <div className="slider-arrows slider-arrows--right">
        <Icon tag="button" type="button" onClick={onClick} icon="angle-right" className="slider-arrows__content" />
      </div>
    </Otherwise>
  </Choose>
);

SliderArrowNext.propTypes = {
  onClick: PropTypes.func,
  outline: PropTypes.bool,
};
SliderArrowNext.defaultProps = {
  onClick: null,
  outline: false,
};

export default SliderArrowNext;
