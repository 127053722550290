import React, { Component, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { Redirect } from 'react-router-dom';
import PropTypes from '../../../constants/prop-types';
import ShortPreloader from '../../../components/ShortPreloader';
import { parseSportsBookParams } from '../utils';
import getDeviceType from '../../../utils/getDeviceType';
import history from '../../../router/history';
import { waitUntil } from '../../../utils';

const REQUIRED_EXTERNAL_SCRIPTS = {
  DESKTOP: () => typeof get(window, 'Bootstrapper.bootIframe') === 'function',
  MOBILE: () => typeof get(window, 'Bootstrapper.bootIframe') === 'function',
};

class ESportsBookView extends Component {
  static propTypes = {
    lang: PropTypes.string.isRequired,
    playerUUID: PropTypes.string,
    startGame: PropTypes.func.isRequired,
    stopGame: PropTypes.func.isRequired,
    profile: PropTypes.shape({
      player: PropTypes.shape({
        data: PropTypes.shape({
          totalBalance: PropTypes.money,
        }),
      }),
      loading: PropTypes.bool.isRequired,
    }),
    profileInfo: PropTypes.shape({
      player: PropTypes.shape({
        data: PropTypes.shape({
          completed: PropTypes.bool,
        }),
      }),
      loading: PropTypes.bool.isRequired,
    }),
    logged: PropTypes.bool.isRequired,
  };

  static contextTypes = {
    mainModals: PropTypes.shape({
      errorModal: PropTypes.modalType,
    }),
  };

  static defaultProps = {
    playerUUID: null,
    profile: {
      loading: false,
    },
    profileInfo: {
      loading: false,
    },
  };

  state = {
    data: null,
    error: null,
    loading: true,
    url: null,
    params: {},
  };

  componentDidMount() {
    const { profile } = this.props;

    this.mounted = true;
    window.EsportsSignIn = this.handleSignIn;
    window.EsportsSignUp = this.handleSignUp;

    if (!profile.loading) {
      this.startGame();
    }
  }

  componentDidUpdate({ lang: prevLang, profile: prevProfile }) {
    const { lang, profile } = this.props;

    if ((!profile.loading && prevProfile.loading) || lang !== prevLang) {
      this.startGame();
    }
  }

  async componentWillUnmount() {
    this.mounted = false;

    await this.stopGame();

    delete window.Bootstrapper;
    delete window.EsportsSignIn;
    delete window.EsportsSignUp;
  }

  mounted = false;

  handleSignIn = () => {
    const { lang } = this.props;
    const { location, push } = history;

    push({
      pathname: `/${lang}/sign-in`,
      state: {
        modal: true,
        prevLocation: location,
      },
    });
  };

  handleSignUp = () => {
    const { lang } = this.props;
    const { location, push } = history;

    push({
      pathname: `/${lang}/sign-up`,
      state: {
        modal: true,
        prevLocation: location,
      },
    });
  };

  startGame = () => {
    const { startGame } = this.props;

    return startGame().then(async (response) => {
      const { data, error } = get(response, 'data.game.start', this.state);
      const nextState = { loading: false, data, error };

      const contentResponse = await fetch(data.gameSessionURL.replace(/https:\/\/api\.([^]+)\//, '/api/'));

      const content = await contentResponse.text();
      const scriptUrlMatches = content.match(/src='([^']+)'/);

      if (scriptUrlMatches) {
        nextState.url = scriptUrlMatches[1];
      }

      if (this.mounted) {
        this.setState(nextState, async () => {
          const scriptIsLoaded = await waitUntil(REQUIRED_EXTERNAL_SCRIPTS[getDeviceType()], 100, 10);

          if (scriptIsLoaded && this.mounted) {
            this.setState({
              params: {
                ...parseSportsBookParams(content),
                login: 'EsportsSignIn',
                registration: 'EsportsSignUp',
              },
            });
          }
        });
      }
    });
  };

  stopGame = async () => {
    const { playerUUID, stopGame } = this.props;
    const { data } = this.state;

    if (data && data.gameSessionId && playerUUID) {
      await stopGame({ variables: { sessionId: data.gameSessionId } });
    }
  };

  render() {
    const { profile, profileInfo, lang, logged } = this.props;
    const { loading, error, url, params } = this.state;

    if (loading || profile.loading || profileInfo.loading) {
      return <ShortPreloader />;
    }

    if (error) {
      return <div>{error}</div>;
    }

    const profileCompleted = get(profileInfo, 'player.data.completed', false);
    const {
      location: { pathname },
    } = history;

    if (logged && !profileCompleted) {
      return <Redirect to={{ pathname: `/${lang}/profile-form`, state: { returnUrl: pathname } }} />;
    }

    return (
      <Fragment>
        <Helmet>
          <script type="text/javascript" src={url} />
          <If condition={!isEmpty(params)}>
            <script>{`Bootstrapper.bootIframe(${JSON.stringify(params)}, { name: 'ESport' })`}</script>
          </If>
        </Helmet>
        <div id="sport_div_iframe" />
      </Fragment>
    );
  }
}

export default ESportsBookView;
