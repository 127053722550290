import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Link from '../../Link';
import Sprite from '../../Sprite';
import './FooterLink.scss';

const FooterLink = ({ link, icon }) => (
  <Link to={link} className="footer-link">
    <Sprite className="lazyload" item={icon} />
  </Link>
);

FooterLink.propTypes = {
  link: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  icon: PropTypes.string.isRequired,
};

export default memo(FooterLink);
