import config from '../config';

const navigation = [
  { label: 't.games', url: '/games/all', key: 'games' },
  { label: 't.live_casino', url: '/games/live_casino', key: 'live_casino' },
  { label: 't.promos', url: '/promo', key: 'promos' },
  { label: 't.calendar', url: '/calendar', key: 'calendar', icon: 'paw' },
  { label: 't.tournaments', url: '/tournaments', key: 'tournaments' },
  { label: 't.lotteries', url: '/lotteries', key: 'lotteries' },
];

if (config.sportsBookEnabled) {
  navigation.splice(1, 0, { label: 't.bet-construct-sports-book', url: '/bc-sports-book', key: 'bc-sports-book' });
}

const footerMenu = [
  { label: 't.rules', url: '/rules' },
  { label: 't.responsible.gaming', url: '/responsible' },
  { label: 't.contacts', url: '/contacts' },
  { label: 't.privacy', url: '/privacy' },
  { label: 't.refund_policy', url: '/refund' },
  { label: 't.faq', url: '/faqs' },
];

const playerMenu = [
  {
    label: 't.profile',
    url: { pathname: '/player/profile', state: { modal: true } },
    icon: 'user',
  },
  {
    label: 't.cashier',
    url: { pathname: '/player/cashbox', state: { modal: true } },
    icon: 'currency',
  },
  {
    label: 't.gifts',
    url: { pathname: '/player/bonuses', state: { modal: true } },
    icon: 'gift',
  },
];

const cashbackMenuOption = {
  label: 't.cashback',
  url: { pathname: '/player/cashback', state: { modal: true } },
  icon: 'spin',
};

const tournamentsMenu = [
  {
    label: 't.tournament.title.CURRENT',
    url: '/tournaments/active',
  },
  {
    label: 't.tournament.title.FINISHED',
    url: '/tournaments/finished',
  },
];

const lotteriesMenu = [
  {
    label: 't.lottery.title.CURRENT',
    url: '/lotteries/active',
  },
  {
    label: 't.lottery.title.FINISHED',
    url: '/lotteries/finished',
  },
];

export { navigation, footerMenu, playerMenu, tournamentsMenu, lotteriesMenu, cashbackMenuOption };
