import React, { PureComponent } from 'react';
import { Nav, NavItem } from 'reactstrap';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from '../../../constants/prop-types';
import { navigation } from '../../../constants/menu';
import { isMobile, liveChatOpenHandler } from '../../../utils';
import renderGameCategoriesArray from '../../../utils/renderGameCategoriesArray';
import { SocialIcons, NavigationLink, Icon } from '../../../components';
import ActiveBonusProgressBar from '../../ActiveBonusProgressBar';
import ThemeSwitcher from '../../ThemeSwitcher';
import './MobileMenu.scss';

class MobileMenu extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    bonus: PropTypes.shape({
      bonusType: PropTypes.string,
      progress: PropTypes.number,
      leftToWage: PropTypes.money,
      amountToWage: PropTypes.money,
    }),
    handleCloseMenu: PropTypes.func.isRequired,
    gameCategories: PropTypes.gameCategories.isRequired,
    logged: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    bonus: {},
  };

  get categories() {
    const { gameCategories } = this.props;

    return renderGameCategoriesArray(gameCategories);
  }

  render() {
    const { bonus, t, handleCloseMenu, logged } = this.props;

    return (
      <Nav className="d-md-none mobile-menu" navbar>
        <If condition={bonus}>
          <NavItem className="d-sm-none mobile-menu__bonus">
            <If condition={bonus.image}>
              <img className="mobile-menu__bonus-image" src={bonus.image} alt="bonus" />
            </If>
            <ActiveBonusProgressBar id="bonus" bonus={bonus} />
          </NavItem>
        </If>
        <If condition={!logged}>
          <NavItem className="mobile-menu__social">
            <SocialIcons label={t('t.login.via.social')} />
          </NavItem>
        </If>
        <NavItem className="mobile-menu__divider" />
        <NavItem>
          <ThemeSwitcher />
        </NavItem>
        <NavItem className="mobile-menu__divider" />
        {navigation.map((item) => (
          <NavItem key={item.key} className="mobile-menu__item">
            <NavigationLink exact {...item} onClick={handleCloseMenu} />
          </NavItem>
        ))}
        <NavItem className="mobile-menu__divider" />
        {this.categories.map((category) => (
          <NavItem key={category.key} className="mobile-menu__item">
            <NavigationLink exact url={category.url} label={category.label} onClick={handleCloseMenu} />
          </NavItem>
        ))}
        <If condition={isMobile.any()}>
          <NavItem className="mobile-menu__divider" />
          <NavItem className="mobile-menu__item">
            <button type="button" className="mobile-menu__button" onClick={liveChatOpenHandler}>
              {t('t.help.online')}
            </button>
          </NavItem>
        </If>
        <If condition={logged}>
          <NavItem className="mobile-menu__divider" />
          <NavItem className="mobile-menu__item">
            <RouterLink to="/logout" className="mobile-menu__logout">
              <Icon icon="logout" className="mobile-menu__icon" />
              {t('t.logout')}
            </RouterLink>
          </NavItem>
        </If>
      </Nav>
    );
  }
}

export default MobileMenu;
