import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';
import { connect } from 'react-redux';
import IndexRoute from '../components/IndexRoute';
import { getBrandId, getDomain } from '../../../config';

const query = gql`
  query options($brandId: String!) {
    options {
      signUp(brandId: $brandId) {
        post {
          currency {
            base
            list
          }
        }
      }
    }
  }
`;

const domainDataQuery = gql`
  query domainData($domain: String!, $locale: String!) {
    domainData(domain: $domain, locale: $locale) {
      data {
        title
        metaDescription
        redirect
      }
    }
  }
`;

const profileCompletionStatusQuery = gql`
  query getProfile($playerUUID: String!) {
    player(playerUUID: $playerUUID) {
      error {
        error
      }
      data {
        _id
        completed
        currency
      }
    }
  }
`;

export default compose(
  connect(({ auth, auth: { uuid, logged }, i18nState: { lang: locale } }) => ({ auth, uuid, logged, locale })),
  graphql(domainDataQuery, {
    options: ({ locale }) => ({
      variables: {
        domain: getDomain(),
        locale,
      },
    }),
    name: 'domainData',
  }),
  graphql(profileCompletionStatusQuery, {
    options: ({ uuid }) => ({
      fetchPolicy: 'network-only',
      variables: {
        playerUUID: uuid,
      },
    }),
    skip: ({ logged }) => !logged,
    name: 'profile',
  }),
  graphql(query, {
    options: () => ({
      variables: {
        brandId: getBrandId(),
      },
    }),
  })
)(IndexRoute);
