import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import withLocalization from '../../../utils/withLocalization';
import Header from './Header';
import { getBrandId } from '../../../config';
import { STATES } from '../../../constants/bonus';
import getDeviceType from '../../../utils/getDeviceType';

const bonusCampaignsQuery = gql`
  query bonusCampaigns($playerUUID: String!, $deviceType: String, $locale: String) {
    bonusCampaigns(playerUUID: $playerUUID, deviceType: $deviceType, locale: $locale) {
      data {
        uuid
        campaignUUID
        parentCampaignUUID
      }
    }
  }
`;

const query = gql`
  query getProfile($playerUUID: String!, $states: String!) {
    bonuses(playerUUID: $playerUUID, limit: 1, page: 0, states: $states) {
      data {
        bonusType
        progress
        amountToWage {
          amount
          currency
        }
        leftToWage {
          amount
          currency
        }
      }
    }
    player(playerUUID: $playerUUID) {
      error {
        error
      }
      data {
        _id
        username
        totalBalance {
          amount
          currency
        }
      }
    }
  }
`;

export default compose(
  withLocalization,
  withRouter,
  connect(({ auth: { uuid, logged }, i18nState: { lang: locale } }) => ({ uuid, logged, locale })),
  graphql(bonusCampaignsQuery, {
    options: ({ uuid, locale }) => ({
      fetchPolicy: 'network-only',
      variables: {
        playerUUID: uuid,
        locale,
        deviceType: getDeviceType(),
      },
      pollInterval: 300000,
    }),
    skip: ({ logged }) => !logged,
    name: 'bonusCampaigns',
  }),
  graphql(query, {
    options: ({ uuid }) => ({
      variables: {
        brandId: getBrandId(),
        playerUUID: uuid,
        states: STATES.IN_PROGRESS,
      },
      pollInterval: 600000,
      fetchPolicy: 'network-only',
    }),
    skip: ({ logged }) => !logged,
    name: 'profile',
  })
)(Header);
