import React from 'react';
import PropTypes from 'prop-types';
import Timer from '../Timer';
import Divider from '../Divider';
import './Banner.scss';

const Banner = ({ image, timer, delay, label }) => (
  <div className="banner">
    <div className="banner__content" style={{ backgroundImage: `url(${image})` }}>
      <If condition={timer}>
        <Timer className="banner__timer" label={label} delay={delay} />
      </If>
    </div>
    <Divider type="colored" className="banner__divider" />
  </div>
);

Banner.propTypes = {
  image: PropTypes.string,
  timer: PropTypes.bool,
  delay: PropTypes.number,
  label: PropTypes.string,
};
Banner.defaultProps = {
  image: '',
  timer: false,
  delay: null,
  label: '',
};

export default Banner;
