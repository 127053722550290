import React from 'react';
import PropTypes from 'prop-types';
import getCurrencySymbol from '../../utils/getCurrencySymbol';

const Currency = ({ code, ...rest }) => <span {...rest}>{getCurrencySymbol(code)}</span>;

Currency.propTypes = {
  code: PropTypes.string.isRequired,
};

export default Currency;
