import PropTypes from 'prop-types';

PropTypes.modalType = PropTypes.shape({
  show: PropTypes.func.isRequired,
  hide: PropTypes.func.isRequired,
});
PropTypes.money = PropTypes.shape({
  amount: PropTypes.number,
  currency: PropTypes.string,
});
PropTypes.pageable = (content) =>
  PropTypes.shape({
    first: PropTypes.bool.isRequired,
    last: PropTypes.bool.isRequired,
    number: PropTypes.number.isRequired,
    numberOfElements: PropTypes.number.isRequired,
    size: PropTypes.number.isRequired,
    sort: PropTypes.arrayOf(
      PropTypes.shape({
        ascending: PropTypes.bool.isRequired,
        direction: PropTypes.string.isRequired,
        ignoreCase: PropTypes.bool.isRequired,
        nullHandling: PropTypes.string.isRequired,
        property: PropTypes.string.isRequired,
      })
    ),
    totalElements: PropTypes.number.isRequired,
    totalPages: PropTypes.number.isRequired,
    content: PropTypes.arrayOf(content).isRequired,
  });
PropTypes.pageableState = (content) =>
  PropTypes.shape({
    entities: PropTypes.pageable(content).isRequired,
    isLoading: PropTypes.bool.isRequired,
    receivedAt: PropTypes.number,
    error: PropTypes.object,
  });
PropTypes.staticPage = PropTypes.shape({
  content: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
});
PropTypes.lotteriesEntity = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.string,
    imageSm: PropTypes.string,
    title: PropTypes.string,
    status: PropTypes.string,
    endDate: PropTypes.string,
    startDate: PropTypes.string,
    shortDescription: PropTypes.string,
    prizes: PropTypes.arrayOf(
      PropTypes.shape({
        prise: PropTypes.money,
        amount: PropTypes.number,
        title: PropTypes.string,
      })
    ),
    prizePool: PropTypes.money,
  })
);
PropTypes.tournamentsEntity = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.string,
    icon: PropTypes.string,
    alias: PropTypes.string,
    title: PropTypes.string,
    prizePool: PropTypes.money,
    status: PropTypes.string,
    endDate: PropTypes.string,
    startDate: PropTypes.string,
    shortDescription: PropTypes.string,
    places: PropTypes.arrayOf(PropTypes.money),
  })
);
PropTypes.calendarLeaderboardEntity = PropTypes.arrayOf(
  PropTypes.shape({
    rank: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    login: PropTypes.string,
    score: PropTypes.string,
    prize: PropTypes.string,
    playerUUID: PropTypes.string,
  })
);

PropTypes.gameCategories = PropTypes.shape({
  loading: PropTypes.bool.isRequired,
  refetch: PropTypes.func,
  gameNgCategories: PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.arrayOf(PropTypes.string),
  }),
});

PropTypes.tag = PropTypes.oneOfType([
  PropTypes.func,
  PropTypes.string,
  PropTypes.shape({ $$typeof: PropTypes.symbol, render: PropTypes.func }),
  PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.string,
      PropTypes.shape({ $$typeof: PropTypes.symbol, render: PropTypes.func }),
    ])
  ),
]);

PropTypes.leaderboardEntity = PropTypes.shape({
  rank: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  username: PropTypes.string,
  amount: PropTypes.string,
  prizeFund: PropTypes.money,
});

PropTypes.game = PropTypes.shape({
  internalGameId: PropTypes.string,
  fullGameName: PropTypes.string,
  image: PropTypes.string,
  isFavorite: PropTypes.bool,
  restricted: PropTypes.bool,
  hasDemoMode: PropTypes.bool,
  slug: PropTypes.string,
  providerAlias: PropTypes.string,
});

PropTypes.requiredFieldEntity = PropTypes.arrayOf(
  PropTypes.shape({
    availableValues: PropTypes.arrayOf(PropTypes.any),
    fieldName: PropTypes.string,
    nameAlias: PropTypes.string,
    regExp: PropTypes.string,
    uuid: PropTypes.string,
  })
);

PropTypes.paymentMethodImagesEntity = PropTypes.shape({
  image: PropTypes.string,
  altImage: PropTypes.string,
  icon: PropTypes.string,
  altIcon: PropTypes.string,
});

export default PropTypes;
