import React, { Component } from 'react';
import PropTypes from 'prop-types';
import renderHTML from './renderHTML';

export default function withLocalization(WrappedComponent) {
  return class Localized extends Component {
    static contextTypes = {
      t: PropTypes.func.isRequired,
    };

    translate = (text, variables) => renderHTML(this.context.t(text, variables));

    render() {
      return <WrappedComponent {...this.props} t={this.translate} />;
    }
  };
}
