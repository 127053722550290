import gql from 'graphql-tag';

const optInMutation = gql`
  mutation optIn($playerUUID: String!, $campaignUUID: String!, $parentCampaignUUID: String, $deviceType: String!) {
    bonusCampaigns {
      optIn(
        playerUUID: $playerUUID
        campaignUUID: $campaignUUID
        parentCampaignUUID: $parentCampaignUUID
        deviceType: $deviceType
      ) {
        data {
          campaignUUID
        }
        error {
          error
        }
      }
    }
  }
`;

export { optInMutation };
