import React from 'react';
import classNames from 'classnames';
import PropTypes from '../../constants/prop-types';
import ALERT_COLORS from './constants';
import './Alert.scss';

const Alert = ({ className, color, tag: Tag, ...rest }) => (
  <Tag {...rest} className={classNames('alert', { [`alert-${color}`]: color }, className)} />
);

Alert.propTypes = {
  className: PropTypes.string,
  color: PropTypes.oneOf(Object.values(ALERT_COLORS)),
  tag: PropTypes.tag,
};

Alert.defaultProps = {
  className: null,
  color: null,
  tag: 'div',
};

Alert.colors = ALERT_COLORS;

export default Alert;
