import React from 'react';
import PropTypes from 'prop-types';
import { withLocalization } from '../../utils';
import Button from '../Button';

const GameGridLoadMore = ({ onClick, t }) => (
  <div className="game-grid__action">
    <Button type="button" className="game-grid__button" onClick={onClick}>
      {t('t.more.games')}
    </Button>
  </div>
);

GameGridLoadMore.propTypes = {
  onClick: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withLocalization(GameGridLoadMore);
