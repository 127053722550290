import keyMirror from 'keymirror';

const categories = {
  FAVORITE: {
    key: 'favorite',
    label: 't.favorites',
    url: '/games/favorite',
  },
};
const types = keyMirror({
  MOBILE: null,
  DESKTOP: null,
});

const modes = {
  REAL: 'real',
  DEMO: 'demo',
};

const restrictedGameModalOptions = {
  title: 't.game.restricted.title',
  content: 't.game.restricted.desc',
};

const gameProviders = keyMirror({
  netent: null,
  tomhorn: null,
});

const gameCategoriesTag = 'gaming_categories';

export { categories, types, modes, restrictedGameModalOptions, gameProviders, gameCategoriesTag };
