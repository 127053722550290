import { connect } from 'react-redux';
import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';
import { actionCreators as signInActionCreators } from '../../../redux/modules/auth';
import AuthToken from '../components/AuthToken';

const mapDispatchToProps = {
  authorizeUser: signInActionCreators.authorizeUser,
};

const useTokenMutation = gql`
  mutation useToken($token: String!) {
    player {
      useToken(token: $token) {
        data {
          uuid
          token
        }
        error {
          error
        }
      }
    }
  }
`;

export default compose(graphql(useTokenMutation, { name: 'useToken' }), connect(null, mapDispatchToProps))(AuthToken);
