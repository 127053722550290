import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Switch } from 'react-router-dom';
import I18n from 'redux-i18n';
import { getUrlWithLang } from '../../utils/url';
import IndexRoute from '../../routes';
import { additionalLanguages, availableLanguages } from '../../constants/languages';
import { Localization, Route } from '../../router';
import Logout from '../Logout';
import AuthToken from '../AuthToken';
import isExplorerAgent from '../../utils/isInternetExplorerCheck';
import ChangeBrowser from '../ChangeBrowser';
import Preloader from '../../components/Preloader';
import MainModals from '../../components/MainModals';
import { withLanguage } from '../../utils';
import TokenSignIn from '../TokenSignIn';

const languages = [...availableLanguages, ...additionalLanguages];

const MainRoute = ({ location: { pathname, search, query }, language: lang, loading }) => (
  <I18n translations={{}} useReducer>
    <MainModals>
      <Choose>
        <When condition={loading}>
          <Preloader />
        </When>
        <When condition={isExplorerAgent}>
          <Localization match={{ params: { lang } }}>
            <Route component={ChangeBrowser} />
          </Localization>
        </When>
        <Otherwise>
          <Switch>
            <Route disableTrackAffiliate component={Logout} path="/logout" />
            <Route disableTrackAffiliate component={TokenSignIn} path="/token-sign-in" />
            <Route disableTrackAffiliate component={AuthToken} path="/auth-token" />
            <Route
              path={`/:lang(${languages.join('|')})`}
              disableTrackAffiliate
              render={(props) => (
                <Localization {...props}>
                  <IndexRoute {...props} />
                </Localization>
              )}
            />
            <Redirect to={{ query, pathname: getUrlWithLang(pathname, lang), search }} />
          </Switch>
        </Otherwise>
      </Choose>
    </MainModals>
  </I18n>
);

MainRoute.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string,
    query: PropTypes.object,
  }).isRequired,
  language: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default withLanguage(MainRoute);
