import React from 'react';
import PropTypes from 'prop-types';
import FormGroup from '../FormGroup';
import Radio from '../../Radio';

const RadioButtonGroup = ({
  input,
  meta: { touched, error },
  options,
  label,
  labelClassName,
  isRequired,
  horizontal,
  className,
  size,
}) => (
  <FormGroup
    touched={touched}
    error={error}
    label={label}
    labelClassName={labelClassName}
    required={isRequired}
    className={className}
    horizontal={horizontal}
  >
    <div className="radio__group">
      {options.map(({ label, value }) => (
        <Radio key={value} size={size} className="mb-0 mr-2" tag="label" label={label}>
          <input {...input} type="radio" value={value} checked={value === input.value} />
        </Radio>
      ))}
    </div>
  </FormGroup>
);

RadioButtonGroup.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.any,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }).isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    })
  ).isRequired,
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  isRequired: PropTypes.bool,
  horizontal: PropTypes.bool,
  className: PropTypes.string,
  size: PropTypes.oneOf(Object.values(Radio.sizes)),
};

RadioButtonGroup.defaultProps = {
  label: null,
  labelClassName: null,
  isRequired: false,
  horizontal: false,
  className: null,
  size: Radio.sizes.sm,
};

export default RadioButtonGroup;
