import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';
import { connect } from 'react-redux';
import Support from './Support';

const profileQuery = gql`
  query getProfileData($playerUUID: String!) {
    player(playerUUID: $playerUUID) {
      error {
        error
      }
      data {
        _id
        currency
        email
        emailVerified
        firstName
        lastName
        phoneNumberVerified
        playerUUID
        registrationDate
        username
      }
    }
  }
`;

export default compose(
  connect(({ auth: { uuid, logged } }) => ({ uuid, logged })),
  graphql(profileQuery, {
    options: ({ uuid }) => ({
      variables: {
        playerUUID: uuid,
      },
    }),
    skip: ({ logged }) => !logged,
    name: 'profile',
  })
)(Support);
