import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Route from '../Route';
import { availableLanguages } from '../../constants/languages';

class ModalRoute extends Component {
  static propTypes = {
    modalProps: PropTypes.shape({
      title: PropTypes.string,
    }),
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
      goBack: PropTypes.func.isRequired,
    }).isRequired,
    component: PropTypes.func.isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        lang: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  };
  static defaultProps = {
    modalProps: {
      title: '',
    },
  };
  static contextTypes = {
    previousLocation: PropTypes.object,
  };

  handleCloseModal = () => {
    const {
      history,
      match: {
        params: { lang },
      },
    } = this.props;
    const {
      previousLocation: { pathname },
    } = this.context;

    history.replace(pathname.replace(new RegExp(`${availableLanguages.join('|')}`), lang), { action: 'POP' });
  };

  render() {
    const {
      component: RouteComponent,
      modalProps: { title, ...modalProps },
      ...rest
    } = this.props;

    return (
      <Route
        {...rest}
        render={(props) => (
          <RouteComponent title={title} handleCloseModal={this.handleCloseModal} {...props} {...modalProps} />
        )}
      />
    );
  }
}

export default withRouter(ModalRoute);
