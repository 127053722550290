export default (value) => {
  if (value === undefined) {
    return undefined;
  }

  if (value === '') {
    return value;
  }

  const parsed = Number.parseInt(value, 10);

  return !Number.isNaN(parsed) ? parsed : undefined;
};
