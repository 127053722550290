import React from 'react';
import classNames from 'classnames';
import PropTypes from '../../constants/prop-types';
import { BUTTON_COLORS } from './constants';
import './Button.scss';

const Button = ({ tag: Tag, children, color, block, className, disabled, ...rest }) => {
  const classes = classNames(
    'button',
    [`button-${color}`],
    {
      'button-block': block,
      disabled: Tag !== 'button' && disabled,
    },
    className
  );

  return (
    <Tag className={classes} disabled={Tag === 'button' && disabled} {...rest}>
      {children}
    </Tag>
  );
};

Button.propTypes = {
  tag: PropTypes.tag,
  type: PropTypes.oneOf(['button', 'reset', 'submit']),
  block: PropTypes.bool,
  color: PropTypes.oneOf(Object.values(BUTTON_COLORS)),
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
  id: PropTypes.string,
  children: PropTypes.node,
};

Button.defaultProps = {
  tag: 'button',
  type: null,
  block: false,
  color: BUTTON_COLORS.primary,
  disabled: false,
  onClick: null,
  className: null,
  id: null,
  children: null,
};

Button.colors = BUTTON_COLORS;

export default Button;
