import { getTheme } from '../../config';
import createReducer from '../../utils/createReducer';
import { themes } from '../../constants/themes';

const TOGGLE_THEME = 'toggle-theme';

function toggleTheme() {
  return {
    type: TOGGLE_THEME,
  };
}

const initialState = getTheme();

const actionHandlers = {
  [TOGGLE_THEME]: (state) => (state === themes.LIGHT ? themes.DARK : themes.LIGHT),
};

const actionTypes = {
  TOGGLE_THEME,
};

const actionCreators = {
  toggleTheme,
};

export { initialState, actionCreators, actionTypes };

export default createReducer(initialState, actionHandlers);
