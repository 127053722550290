import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose, graphql } from 'react-apollo';
import get from 'lodash/get';
import { connect } from 'react-redux';
import { withRouter, matchPath } from 'react-router-dom';
import gql from 'graphql-tag';
import { availableLanguages, defaultLanguage } from '../constants/languages';
import withGeoLocation from './withGeoLocation';

const profileQuery = gql`
  query ProfileLanguageCodeQuery($playerUUID: String!) {
    player(playerUUID: $playerUUID) {
      data {
        _id
        languageCode
      }
    }
  }
`;

const withLanguage = (WrappedComponent) => {
  const WithLanguageComponent = class WithLanguage extends Component {
    static propTypes = {
      location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
      }).isRequired,
      profile: PropTypes.shape({
        player: PropTypes.shape({
          data: PropTypes.shape({
            languageCode: PropTypes.string,
          }),
        }),
      }),
      geoLocation: PropTypes.shape({
        language: PropTypes.string.isRequired,
      }).isRequired,
      uuid: PropTypes.string,
    };

    static defaultProps = {
      profile: null,
      uuid: null,
    };

    getProps = () => {
      const {
        profile,
        location: { pathname },
        geoLocation: { language: geoLanguage },
        uuid,
        location,
      } = this.props;

      const fallbackLanguage = availableLanguages.includes(geoLanguage) ? geoLanguage : defaultLanguage;
      const language =
        get(matchPath(pathname, { path: `/:lang(${availableLanguages.join('|')})` }), 'params.lang') ||
        fallbackLanguage;

      if (uuid) {
        const loading = get(profile, 'loading') || false;
        const playerLanguage = get(profile, 'player.data.languageCode');

        return {
          loading,
          language: loading ? language : playerLanguage,
          location,
        };
      }

      return {
        loading: false,
        language,
        location,
      };
    };

    render() {
      return <WrappedComponent {...this.getProps()} />;
    }
  };

  return compose(
    withRouter,
    connect(({ auth: { uuid, logged } }) => ({ uuid, logged })),
    withGeoLocation,
    graphql(profileQuery, {
      name: 'profile',
      options: ({ uuid }) => ({
        variables: {
          playerUUID: uuid,
        },
        fetchPolicy: 'cache-and-network',
      }),
      skip: ({ logged }) => !logged,
    })
  )(WithLanguageComponent);
};

export default withLanguage;
