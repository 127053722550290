import React from 'react';
import { Nav, NavItem } from 'reactstrap';
import { footerMenu } from '../../../constants/menu';
import NavigationLink from '../../NavigationLink';
import './FooterNav.scss';

const FooterNav = () => (
  <Nav className="footer-nav">
    {footerMenu.map(({ label, url }) => (
      <NavItem key={label} className="footer-nav__item">
        <NavigationLink label={label} url={url} />
      </NavItem>
    ))}
  </Nav>
);

export default FooterNav;
