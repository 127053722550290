import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './ContentDivider.scss';

const ContentDivider = ({ className, isHorizontal }) => (
  <div className={classNames('content-divider', isHorizontal ? 'horizontal' : 'vertical', className)} />
);

ContentDivider.propTypes = {
  className: PropTypes.string,
  isHorizontal: PropTypes.bool,
};

ContentDivider.defaultProps = {
  className: '',
  isHorizontal: false,
};

export default ContentDivider;
