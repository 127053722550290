import { RSAA } from 'redux-api-middleware';
import get from 'lodash/get';
import createReducer from '../../utils/createReducer';
import { getAffiliateUrl, getBrandId } from '../../config';
import createRequestAction from '../../utils/createRequestAction';

const validAffiliateFields = ['affiliateId', 'btag', 'referrerUserId'];

const reduceValidFields = (fields) =>
  Object.keys(fields).reduce((result, name) => {
    if (validAffiliateFields.indexOf(name) > -1) {
      return { ...result, [name]: fields[name] };
    }

    return result;
  }, {});

const KEY = 'affiliate';
const SET_FIELDS = `${KEY}/set-affiliate-fields`;
const CLEAR = `${KEY}/clear`;
const GET_VISITOR = createRequestAction(`${KEY}/get-visitor`);

function getVisitor(btag) {
  return {
    [RSAA]: {
      endpoint: `${getAffiliateUrl()}/visitor?brandId=${getBrandId()}`,
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...JSON.parse(btag),
        http_referer: document.referrer,
      }),
      types: [GET_VISITOR.REQUEST, GET_VISITOR.SUCCESS, GET_VISITOR.FAILURE],
    },
  };
}

function setAffiliateFields(sourceFields, shouldClearPrevious = false) {
  return async (dispatch) => {
    const fields = { ...sourceFields };

    if (fields.btag && getAffiliateUrl()) {
      const res = await dispatch(getVisitor(fields.btag));
      const referrerUserId = get(res, 'payload.referrerUserId');

      fields.referrerUserId = referrerUserId;
      fields.btag = JSON.stringify({ ...JSON.parse(fields.btag), referrerUserId });
    }

    dispatch({
      type: SET_FIELDS,
      payload: {
        fields,
        shouldClearPrevious,
      },
    });
  };
}

function clear() {
  return {
    type: CLEAR,
  };
}

const initialState = {};
const actionHandlers = {
  [SET_FIELDS]: (state, { payload }) =>
    reduceValidFields({
      ...(payload.shouldClearPrevious ? {} : state),
      ...payload.fields,
    }),
  [CLEAR]: () => ({ ...initialState }),
};

const actionTypes = {
  SET_FIELDS,
  CLEAR,
};
const actionCreators = {
  setAffiliateFields,
  clear,
};

export { initialState, actionTypes, actionCreators, actionHandlers };

export default createReducer(initialState, actionHandlers);
