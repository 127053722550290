import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Link from '../Link';
import Icon from '../Icon';
import { withLocalization } from '../../utils';
import './ButtonBack.scss';

const ButtonBack = ({ link, label, onClick, t, className }) => {
  const nodeClassName = classNames('button-back', className);

  return (
    <Choose>
      <When condition={typeof onClick === 'function'}>
        <button type="button" onClick={onClick} className={nodeClassName}>
          <Icon icon="arrow-left" className="button-back__icon" />
          {t(label)}
        </button>
      </When>
      <When condition={link}>
        <Link className={nodeClassName} to={link}>
          <Icon icon="arrow-left" className="button-back__icon" />
          {t(label)}
        </Link>
      </When>
    </Choose>
  );
};

ButtonBack.propTypes = {
  link: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  label: PropTypes.string,
  onClick: PropTypes.func,
  t: PropTypes.func.isRequired,
  className: PropTypes.string,
};

ButtonBack.defaultProps = {
  link: null,
  label: 't.btn.goBack',
  onClick: null,
  className: null,
};

export default withLocalization(ButtonBack);
