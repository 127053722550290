import React from 'react';
import classNames from 'classnames';
import PropTypes from '../../constants/prop-types';
import RADIO_SIZES from './constants';
import './Radio.scss';

const Radio = ({ tag: Tag, children, size, className, label, active, onClick, ...rest }) => (
  <Tag {...rest} className={classNames('radio', size, { active }, className)} onClick={onClick}>
    {children}
    <span className="radio__dot" />
    <If condition={label}>
      <span className="radio__label">{label}</span>
    </If>
  </Tag>
);

Radio.propTypes = {
  tag: PropTypes.tag,
  children: PropTypes.node,
  size: PropTypes.oneOf(Object.values(RADIO_SIZES)),
  className: PropTypes.string,
  active: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClick: PropTypes.func,
};

Radio.defaultProps = {
  tag: 'span',
  children: null,
  size: RADIO_SIZES.sm,
  className: null,
  active: false,
  label: '',
  onClick: null,
};

Radio.sizes = RADIO_SIZES;

export default Radio;
