import { connect } from 'react-redux';
import { graphql, compose } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import VeliPlayGameView from '../components/VeliPlayGameView';
import { getBrandId } from '../../../config';
import { startGame, stopGame } from '../../../apollo/mutations/game';
import { getDeviceType, withGeoLocation } from '../../../utils';
import { modes } from '../../../constants/games';

export default compose(
  withRouter,
  withGeoLocation,
  connect(({ auth: { uuid: playerUUID, token, logged }, i18nState: { lang } }) => ({
    playerUUID,
    lang,
    token,
    logged,
  })),
  graphql(startGame, {
    name: 'startGame',
    options: ({
      lang,
      playerUUID,
      geoLocation: { country },
      match: {
        params: { gameId },
      },
    }) => ({
      variables: {
        id: `${gameId}:DESKTOP_AND_MOBILE:veliplay:veligames`,
        gameType: getDeviceType(),
        mode: modes.REAL,
        exitUrl: `${window.location.origin}/${lang}`,
        brandId: getBrandId(),
        playerUUID,
        lang,
        country,
      },
    }),
  }),
  graphql(stopGame, {
    name: 'stopGame',
    options: ({
      playerUUID,
      geoLocation: { country },
      match: {
        params: { gameId },
      },
    }) => ({
      variables: {
        id: `${gameId}:DESKTOP_AND_MOBILE:veliplay:veligames`,
        gameType: getDeviceType(),
        playerUUID,
        country,
      },
    }),
  })
)(VeliPlayGameView);
