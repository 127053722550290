import { graphql, compose } from 'react-apollo';
import { connect } from 'react-redux';
import gql from 'graphql-tag';
import { withRouter } from 'react-router-dom';
import SocialIcons from './SocialIcons';
import { getBrandId } from '../../config';

const socialProvidersQuery = gql`
  query SocialIcons($brandId: String!) {
    options {
      socialProviders(brandId: $brandId) {
        alias
        name
      }
    }
  }
`;

const socialAccountsQuery = gql`
  query SocialAccounts($playerUUID: String!) {
    playerSocialAccounts(playerUUID: $playerUUID) {
      data {
        id
        provider
      }
    }
  }
`;

export default compose(
  withRouter,
  connect(({ auth: { logged, uuid }, affiliate }) => ({ logged, uuid, affiliate })),
  graphql(socialProvidersQuery, {
    name: 'socialProviders',
    options: () => ({
      variables: {
        brandId: getBrandId(),
      },
    }),
  }),
  graphql(socialAccountsQuery, {
    options: ({ logged, uuid }) => ({
      variables: {
        playerUUID: uuid,
      },
    }),
    skip: ({ logged }) => !logged,
    name: 'socialAccounts',
  })
)(SocialIcons);
