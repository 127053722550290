import { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { getBrandId } from '../../config';
import { initLiveChat, destroyLiveChat } from './utils';

const BRAND_ID = getBrandId();

const Support = ({ lang, profile: { player, loading }, logged }) => {
  const handleSetLiveChatInformation = useCallback(() => {
    const { LiveChatWidget, LC_API } = window;

    const clientInformation = get(player, 'data', null);

    if (LiveChatWidget && clientInformation && logged) {
      const {
        playerUUID,
        registrationDate,
        username,
        email,
        firstName = '',
        lastName = '',
        emailVerified,
        phoneNumberVerified,
        currency,
      } = clientInformation;

      const customerName = [firstName, lastName].filter(Boolean).join(' ') || username;

      const session_variables = {
        brandId: BRAND_ID,
        currency,
        email_verified: emailVerified,
        phone_verified: phoneNumberVerified,
        playerUUID,
        reg_date: registrationDate,
        username,
      };

      LiveChatWidget.call('set_session_variables', session_variables);
      LiveChatWidget.call('set_customer_email', email);
      LiveChatWidget.call('set_customer_name', customerName);

      if (LC_API) {
        LC_API.on_chat_ended = () => {
          LiveChatWidget.call('set_customer_email', email);
          LiveChatWidget.call('set_customer_name', customerName);
        };
      }
    }

    if (LiveChatWidget && !logged) {
      LiveChatWidget.call('set_session_variables', {
        brandId: BRAND_ID,
        currency: '',
        email_verified: '',
        phone_verified: '',
        playerUUID: '',
        reg_date: '',
        username: '',
      });
      LiveChatWidget.call('set_customer_email', ' ');
      LiveChatWidget.call('set_customer_name', ' ');

      if (LC_API) {
        LC_API.on_chat_ended = () => {
          LiveChatWidget.call('set_customer_email', ' ');
          LiveChatWidget.call('set_customer_name', ' ');
        };
      }
    }
  }, [logged, player]);

  useEffect(() => {
    const handleLiveChat = async (lang) => {
      await destroyLiveChat();
      await initLiveChat(lang);

      handleSetLiveChatInformation();
    };

    if (lang && !loading) {
      handleLiveChat(lang);
    }
  }, [handleSetLiveChatInformation, lang, loading]);

  return null;
};

Support.propTypes = {
  lang: PropTypes.string.isRequired,
  logged: PropTypes.bool.isRequired,
  profile: PropTypes.shape({
    player: PropTypes.shape({
      data: PropTypes.shape({
        playerUUID: PropTypes.string,
        registrationDate: PropTypes.string,
        username: PropTypes.string,
        email: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        emailVerified: PropTypes.bool,
        phoneNumberVerified: PropTypes.bool,
        currency: PropTypes.string,
      }),
    }),
  }),
};

Support.defaultProps = {
  profile: {},
};

export default Support;
