const amountFromMultiCurrency = (currency, amounts) => {
  if (!currency || !amounts || !amounts.length) {
    return null;
  }

  const userCurrencyAmount = amounts.find((amount) => amount.currency === currency);

  return userCurrencyAmount ? userCurrencyAmount.amount : null;
};

export default amountFromMultiCurrency;
