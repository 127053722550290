import { hot, setConfig } from 'react-hot-loader';
import React from 'react';
import PropTypes from 'prop-types';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import ApolloProvider from '../apollo/ApolloProvider';
import Metrics from '../components/Metrics';
import MaintenanceProvider from '../maintenance';
import '../styles/main.scss';
import MainRoute from '../routes/MainRoute';
import history from '../router/history';
import ThemeProvider from './ThemeProvider';

if (process.env.NODE_ENV === 'development') {
  setConfig({ logLevel: 'debug' });
}

const App = ({ reduxStore }) => (
  <Provider store={reduxStore}>
    <ThemeProvider>
      <ApolloProvider>
        <Router history={history}>
          <MaintenanceProvider>
            <MainRoute />
            <If condition={!process.env.NODE_ENV === 'development'}>
              <Metrics />
            </If>
          </MaintenanceProvider>
        </Router>
      </ApolloProvider>
    </ThemeProvider>
  </Provider>
);

App.propTypes = {
  reduxStore: PropTypes.object.isRequired,
};

export default hot(module)(App);
